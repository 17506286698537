<template>
  <footer class="footer__container">
    <img class="footer__star" :src="stardImg" alt="" />
    <img class="footer__bird" :src="birdImg" alt="" />
    <div class="footer__wrapper container">
      <p class="water-mark">ShtarSystem © 2023</p>
      <a
        href="https://centrismedia.uz/"
        target="_blank"
        class="centris-media-mark"
        >СДЕЛАНО В <img class="cm-logo" :src="cmLogo" alt=""
      /></a>
      <div class="footer__left-container">
        <div class="footer__header">
          <div class="footer__title-container">
            <p class="footer__title">shtar system</p>
            <p class="footer__subtitle">
              Все виды систем безопасности и охраны
            </p>
          </div>
          <router-link to="/home">
            <img class="footer__logo" :src="logoImg" alt="" />
          </router-link>
        </div>
        <div class="footer__menu-list">
          <p class="footer__menu-title">меню</p>
          <div class="footer__menu-item__container">
            <router-link to="/home">
              <p class="footer__link-animation">Главная</p>
            </router-link>
            <router-link to="/home#about">
              <p class="footer__link-animation">О компании</p></router-link
            >
            <router-link to="/home#services">
              <p class="footer__link-animation">
                Мы предоставляем
              </p></router-link
            >
            <router-link to="/home#clients">
              <p class="footer__link-animation">Нам доверяют</p></router-link
            >
          </div>
        </div>
      </div>
      <div class="footer__center-container">
        <div class="footer__services-list">
          <p class="footer__services-title">Мы предлагаем</p>
          <div class="footer__services-item__container">
            <p class="footer__link-animation" @click="toServices1">
              Поставка оборудования
            </p>
            <p class="footer__link-animation" @click="toServices2">
              Монтажные работы
            </p>
            <p class="footer__link-animation" @click="toServices3">
              Пуско-наладочные работы
            </p>
            <p class="footer__link-animation" @click="toServices4">
              Проектирование
            </p>
            <p class="footer__link-animation" @click="toServices5">
              Сервисное обслуживание
            </p>
          </div>
        </div>
      </div>
      <div class="footer__right-container">
        <div class="footer__contacts-list">
          <p id="contacts" class="footer__contacts-title">контакты</p>
          <div class="footer__contacts-item__container">
            <a :href="linkFirstNumberPhone">{{ prettify(firstNumberPhone) }}</a>
            <a :href="linkSecondNumberPhone">{{
              prettify(secondNumberPhone)
            }}</a>
            <a :href="linkEmail">{{ email }}</a>
            <a :href="addressLink" target="_blank" v-html="address"></a>
            <p>{{ workingHour }}</p>
          </div>
        </div>
        <div class="footer__social-container">
          <a :href="tg" target="_blank" class="footer__social"
            ><img :src="tgImg" alt=""
          /></a>
          <a :href="inst" target="_blank" class="footer__social"
            ><img :src="instImg" alt=""
          /></a>
          <a :href="fb" target="_blank" class="footer__social"
            ><img :src="fbImg" alt=""
          /></a>
        </div>
        <div class="footer__title-container-mob">
          <p class="footer__title-mob">shtar system</p>
          <p class="footer__subtitle-mob">
            Все виды систем безопасности <br />
            и охраны
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { prettify } from "@/use/prettify.js";
import axios from "axios";
export default {
  data() {
    return {
      birdImg: require("@/assets/img/footer/bird.svg"),
      fbImg: require("@/assets/img/footer/fb.svg"),
      instImg: require("@/assets/img/footer/inst.svg"),
      logoImg: require("@/assets/img/header/logo_header.svg"),
      stardImg: require("@/assets/img/footer/star.svg"),
      tgImg: require("@/assets/img/footer/tg.svg"),
      cmLogo: require("@/assets/img/footer/CM-logo.png"),
      prettify,

      footerContent: {},
      isLoadedPage: false,

      firstNumberPhone: "",
      secondNumberPhone: "",
      email: "",
      address: "",
      addressLink: "",
      workingHour: "",
    };
  },

  mounted() {
    async function getFooterContent() {
      const response = await axios.get(
        `https://bot.shtar.uz/backend/api/v1/site/content/2/`
      );

      return response.data;
    }
    getFooterContent().then((result) => {
      this.footerContent = result;
      this.isLoadedPage = true;
    });
  },
  computed: {
    linkFirstNumberPhone() {
      return "tel:+998" + this.firstNumberPhone;
    },
    linkSecondNumberPhone() {
      return "tel:+998" + this.secondNumberPhone;
    },
    linkEmail() {
      return "mailto:" + this.email;
    },

    tg() {
      if (this.isLoadedPage) {
        const hasLink = this.footerContent.social_media.filter(
          (obj) => obj.title == "Telegram"
        ).length;
        if (hasLink) {
          return this.footerContent.social_media.filter(
            (obj) => obj.title == "Telegram"
          )[0].link;
        } else {
          return "";
        }
      }
    },

    inst() {
      if (this.isLoadedPage) {
        const hasLink = this.footerContent.social_media.filter(
          (obj) => obj.title == "Instagram"
        ).length;

        if (hasLink) {
          return this.footerContent.social_media.filter(
            (obj) => obj.title == "Instagram"
          )[0].link;
        } else {
          return "";
        }
      }
    },

    fb() {
      if (this.isLoadedPage) {
        const hasLink = this.footerContent.social_media.filter(
          (obj) => obj.title == "Facebook"
        ).length;
        if (hasLink) {
          return this.footerContent.social_media.filter(
            (obj) => obj.title == "Facebook"
          )[0].link;
        } else {
          return "";
        }
      }
    },
  },
  watch: {
    footerContent() {
      this.firstNumberPhone = this.footerContent.contact.phoneNumber;
      this.secondNumberPhone = this.footerContent.contact.phoneNumberTwo;
      this.email = this.footerContent.contact.info;
      this.address = this.footerContent.contact.address;
      this.addressLink = this.footerContent.contact.addressLink;
      this.workingHour = this.footerContent.contact.workingHour;
    },
  },

  methods: {
    async toServices1() {
      this.$router.push("/home#services1");
      this.$emit("toServices", 1);
    },
    toServices2() {
      this.$router.push("/home#services2");
      this.$emit("toServices", 2);
    },
    toServices3() {
      this.$router.push("/home#services3");
      this.$emit("toServices", 3);
    },
    toServices4() {
      this.$router.push("/home#services4");
      this.$emit("toServices", 4);
    },
    toServices5() {
      this.$router.push("/home#services5");
      this.$emit("toServices", 5);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer__container {
  position: relative;

  overflow: hidden;

  width: 100%;

  padding: 125px 0 100px 0;
}
.footer__star {
  position: absolute;
  top: 55px;
  left: 64px;
}
.footer__bird {
  position: absolute;
  right: 0;
  bottom: 0;
}
.container {
  max-width: 1520px;

  margin: 0 auto;
}
.footer__wrapper {
  position: relative;
  display: flex;

  border-bottom: solid 1px #c4c4c4;
}
.water-mark {
  position: absolute;

  bottom: -50px;
  right: 0;

  color: #282728;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15.68px; /* 130.667% */
  text-transform: uppercase;
}
.centris-media-mark {
  display: flex;
  align-items: center;
  column-gap: 7px;

  position: absolute;
  bottom: -50px;
  left: 0;

  color: #282728;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15.68px; /* 130.667% */
  text-transform: uppercase;
}
.cm-logo {
  width: 84px;
  height: 22px;
}
.footer__left-container {
  display: flex;
  justify-content: space-between;

  width: 100%;

  padding: 100px 0 60px 0;
}
.footer__header {
  display: flex;
  flex-direction: column;
  row-gap: 75px;
}
.footer__title-container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.footer__title {
  color: #282728;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 15.68px; /* 98% */
  text-transform: uppercase;
}
.footer__subtitle {
  color: #282728;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.5px; /* 129.167% */
}
.footer__logo {
  width: 87px;
  height: 92px;
}
.footer__menu-list {
  display: flex;
  flex-direction: column;
  row-gap: 77px;
}
.footer__menu-title {
  color: #282728;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15.68px; /* 130.667% */
  text-transform: uppercase;
}
.footer__menu-item__container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  p {
    color: #282728;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.68px; /* 130.667% */

    cursor: pointer;
  }
}
.footer__center-container {
  display: flex;
  justify-content: center;

  max-width: 274px;
  width: 100%;

  margin: 0 50px;
  padding: 100px 0 60px 0;

  border-left: solid 1px #c4c4c4;
  border-right: solid 1px #c4c4c4;
}
.footer__services-list {
  display: flex;
  flex-direction: column;
  row-gap: 37px;
}
.footer__services-title {
  color: #282728;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15.68px; /* 130.667% */
  text-transform: uppercase;
}
.footer__services-item__container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  p {
    color: #282728;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.68px; /* 130.667% */

    cursor: pointer;
  }
}
.footer__right-container {
  display: flex;
  justify-content: space-between;

  width: 100%;

  padding: 100px 0 60px 0;
}
.footer__contacts-list {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}
.footer__contacts-title {
  color: #282728;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15.68px; /* 130.667% */
  text-transform: uppercase;
}
.footer__contacts-item__container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  p,
  a {
    color: #282728;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.68px; /* 130.667% */
    text-decoration: none;

    cursor: pointer;
  }
}
.footer__social-container {
  display: flex;
  flex-direction: column;
  row-gap: 34px;
}
.footer__social {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 55px;
  height: 55px;

  border-radius: 50%;
  border: solid 2px #dfdfdf;

  transition: all 0.2s linear 0s;

  cursor: pointer;

  &:hover {
    transition: all 0.2s linear 0s;

    border-color: #282728;
  }
}
.footer__link-animation {
  position: relative;

  transition: all 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  &::before {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);

    content: "";

    width: 4px;
    height: 4px;

    background: transparent;
    border-radius: 50%;

    transition: all 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  &:hover {
    transform: translateX(15px);
    transition: all 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    &::before {
      transform: translate(-25px, -50%);

      background: #282728;

      transition: all 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }
  }
}
.footer__title-container-mob {
  display: none;
}

@media (max-width: 1560px) {
  .water-mark {
    right: 20px;
  }
  .centris-media-mark {
    left: 20px;
  }
  .footer__wrapper {
    padding: 0 20px;
  }
}
@media (max-width: 1560px) {
  .footer__subtitle {
    max-width: 140px;
  }
}

@media (max-width: 990px) {
  .footer__wrapper {
    flex-direction: column;

    border: none;
  }
  .footer__header {
    display: none;
  }
  .footer__left-container {
    justify-content: center;
    padding: 25px 0;
  }
  .footer__center-container {
    max-width: 100%;
    padding: 25px 0;
    margin: 0;
    border: none;
    border-top: solid 1px #dcdcdc;
    border-bottom: solid 1px #dcdcdc;
  }
  .footer__right-container {
    flex-direction: column;
    justify-content: center;
    row-gap: 65px;

    padding: 25px 0;
  }
  .footer__social-container {
    flex-direction: row;
    justify-content: center;
    column-gap: 40px;
  }
  .footer__contacts-list {
    align-items: center;
  }
  .footer__menu-list,
  .footer__services-list,
  .footer__contacts-list {
    row-gap: 37px;

    text-align: center;
  }
  .footer__title-container-mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }
  .footer__title-mob {
    color: #282728;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 15.68px; /* 98% */
    text-transform: uppercase;
  }
  .footer__subtitle-mob {
    color: #282728;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.5px; /* 129.167% */
    text-align: center;
  }
}

@media (max-width: 550px) {
  .footer__container {
    padding-top: 45px;
  }
  .footer__star {
    display: none;
  }
  .footer__bird {
    right: -200px;
  }
  .water-mark {
    right: 50%;
    bottom: -50px;
    transform: translateX(50%);
  }

  .centris-media-mark {
    left: 50%;
    bottom: -90px;
    transform: translateX(-50%);
  }
}
</style>
