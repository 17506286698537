import { defineStore } from "pinia";

export const useCounterStore = defineStore("counter", {
  state: () => ({
    activePage: 1,
  }),
  getters: {},
  actions: {
    setActivePage(id) {
      this.activePage = id;
    },
  },
});