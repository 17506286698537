<template>
  <div id="services" class="services__container container">
    <p class="services__title">МЫ ПРЕДОСТАВЛЯЕМ:</p>
    <div class="services-row__list">
      <app-services-row-vue
        v-for="item in servicesRow"
        :key="item.idx"
        :title="item.title"
        :subtitle="item.subtitle"
        :description="item.description"
        :descriptionTwo="item.descriptionTwo"
        :idx="item.idx"
        :isOpen="item.isOpen"
        :isHeightValue="item.isHeightValue"
        @mark="toggleRow"
      ></app-services-row-vue>
    </div>
  </div>
</template>

<script>
import AppServicesRowVue from "./AppServicesRow.vue";
import { useMainPage } from "../../store/index";
export default {
  inject: ["activeServiceS", "pageContentt"],
  data() {
    return {
      servicesRow: [],
    };
  },
  mounted() {
    if (this.activeServiceS) {
      setTimeout(() => this.toggleRow(this.activeServiceS, 0, true), 1200);
    }
  },
  methods: {
    toggleRow(idx, heightValue, isFooter) {
      const item = this.servicesRow.find((item) => item.idx === idx);
      const localStorage = item.isOpen;
      if (isFooter) {
        this.servicesRow.forEach((item) => {
          item.isOpen = false;
        });
        item.isOpen = true;
      } else {
        this.servicesRow.forEach((item) => {
          item.isOpen = false;
        });
        item.isOpen = !localStorage;
      }
    },
  },
  watch: {
    activeServiceS() {
      setTimeout(() => this.toggleRow(this.activeServiceS, 0, true), 1200);
    },

    pageContentt() {
      let idx = 1;
      this.servicesRow = this.pageContentt.service.map((product) => ({
        ...product,
        isOpen: false,
        idx: idx,
        subtitle: `0${idx++}.`,
      }));
    },
  },
  components: { AppServicesRowVue },
};
</script>

<style lang="scss" scoped>
$maxWidth: 1920;
@mixin adaptiv-font($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $maxWidth: $maxWidth - 375;
  font-size: calc(
    #{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth})
  );
  @media (min-width: 1920px) {
    font-size: $pcSize + px;
  }
}

@mixin adaptiv-line-height($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $maxWidth: $maxWidth - 375;
  line-height: calc(
    #{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth})
  );
  @media (min-width: 1920px) {
    line-height: $pcSize + px;
  }
}

.container {
  max-width: 1520px;

  margin: 0 auto;
}
.services__container {
  display: flex;
  flex-direction: column;
  row-gap: 100px;
}
.services__title {
  color: #29344d;
  font-family: Inter, sans-serif;
  @include adaptiv-font(60, 24);
  font-style: normal;
  font-weight: 600;
  @include adaptiv-line-height(83, 37);
}
.services-row__list {
  display: flex;
  flex-direction: column;
  row-gap: 55px;
}

@media (max-width: 1560px) {
  .services__container {
    padding: 0 20px;
  }
}
@media (max-width: 762px) {
  .services__container {
    row-gap: 50px;
  }
}
</style>
