import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import "./reset_style.css";
import { createPinia } from 'pinia'

const app = createApp(App).use(createPinia());

app.use(router).mount("#app");
