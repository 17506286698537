<template>
  <div class="modal__container" v-if="openThisNameModalWindows">
    <div class="modal__br" @click="closeForm"></div>
    <div class="modal__form-container">
      <div class="modal__close-btn" @click="closeForm"></div>
      <div class="modal__form-title_container">
        <div class="modal__form-title">оставьте заявку</div>
        <div class="modal__form-subtitle">
          Оставьте заявку и в ближайшее время <br />
          с вами свяжутся наши менеджера
        </div>
      </div>
      <form class="modal-form__body" @submit.prevent="submitForm()">
        <div class="modal-form__body-wrapper">
          <input
            v-model.trim="name"
            type="text"
            name=""
            id=""
            placeholder="Имя *"
            required
          />
          <input
            v-model="tel"
            @click="tel == '' ? (tel = '+998') : true"
            type="tel"
            name=""
            id=""
            placeholder="Номер телефона *"
            required
          />
        </div>
        <div class="modal-form__btn-container">
          <div class="modal-form__btn-container">
            <button
              class="modal-form__btn"
              :class="{ 'modal-form__btn-active': sent }"
              type="submit"
            >
              {{ btnText }}
              <span class="checkmark"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 24 24"
                >
                  <polyline
                    class="path"
                    fill="none"
                    points="4,12 9,17 20,6"
                    stroke="#fff"
                    stroke-width="3"
                  /></svg
              ></span>

              <div
                class="main-circle"
                :class="{ 'main-circle-active': startPost }"
              >
                <div class="green-circle">
                  <div class="brown-circle"></div>
                </div>
              </div>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  inject: ["isOpenForm"],
  props: ["id"],
  data() {
    return {
      openThisNameModalWindows: false,

      startPost: false,
      sent: false,

      name: "",
      tel: "",
    };
  },
  watch: {
    isOpenForm() {
      this.openThisNameModalWindows = this.isOpenForm;
      this.startPost = false;
      this.sent = false;
    },
    sent() {
      const checkmark = document.querySelector(".checkmark");
      if (this.sent) {
        setTimeout(function () {
          checkmark.classList.add("show-checkmark");
        }, 1000);
      }
    },
  },

  methods: {
    async submitForm() {
      this.startPost = true;

      let typeForm = undefined;
      if (this.id == 1) {
        typeForm = "/api/v1/leaveARequest/supply/";
      } else if (this.id == 2) {
        typeForm = "/api/v1/leaveARequest/montage/";
      } else if (this.id == 3) {
        typeForm = "/api/v1/leaveARequest/commissioning/";
      } else if (this.id == 4) {
        typeForm = "/api/v1/leaveARequest/designing/";
      } else if (this.id == 5) {
        typeForm = "/api/v1/leaveARequest/service/";
      } else {
        typeForm = "/api/v1/leaveARequest/questions/main/";
      }
      await axios.post(
        `https://bot.shtar.uz/backend${typeForm}`,
        {
          name: this.name,
          phoneNumber: this.tel,
        }
      );

      this.startPost = false;
      this.sent = true;

      this.name = "";
      this.tel = "";
    },
    closeForm() {
      this.$emit("closeForm");
    },
  },
  computed: {
    btnText() {
      if (this.sent) {
        return "";
      } else if (this.startPost) {
        return "";
      } else if (!this.sent) {
        return "оставить заявку";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$maxWidth: 1920;
@mixin adaptiv-font($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $maxWidth: $maxWidth - 375;
  font-size: calc(
    #{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth})
  );
	@media (min-width: 1920px) {
		font-size: $pcSize + px;
	}
}

@mixin adaptiv-line-height($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $maxWidth: $maxWidth - 375;
  line-height: calc(
    #{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth})
  );  	
  @media (min-width: 1920px) {
		line-height: $pcSize + px;
	}
}

.modal__close-btn {
  display: none;
  position: absolute;
  top: 20px;
  right: 25px;

  width: 30px;
  height: 30px;

  &::before,
  &::after {
    position: absolute;
    content: "";

    width: 30px;
    height: 2px;

    background-color: #29344d;
  }

  &::before {
    transform: rotate(45deg);
    top: 15px;
    left: 0;
  }
  &::after {
    transform: rotate(-45deg);
    top: 15px;
    left: 0;
  }

  cursor: pointer;
}

.modal__container {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 28;
}
.modal__br {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: #00000046;
  z-index: 29;
}
.modal__form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 50px;

  padding: 125px 50px 150px 50px;

  background: #f0f0f0;

  z-index: 30;
}
.modal__form-title_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
}
.modal__form-title {
  color: #282728;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 16.174px; /* 80.868% */
  text-transform: uppercase;
}
.modal__form-subtitle {
  color: #282728;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.174px; /* 115.526% */

  text-align: center;
}

.modal-form__body {
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  width: 375px;
  input {
    padding: 20px 20px;

    color: #29344d;
    font-family: Inter, sans-serif;
    font-size: 18.556px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.556px; /* 100% */

    outline: none;
    border-radius: 20px;
    border: 1px solid #282728;

    background: #f0f0f0;

    &,
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    &::placeholder {
      color: #656467;
      font-family: Inter, sans-serif;
      @include adaptiv-font(18, 13);
      font-style: normal;
      font-weight: 400;
      line-height: 18.556px; /* 100% */
    }
  }
  .modal-form__body-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 11px;

    width: 100%;
  }
  .modal-form__btn-container {
    display: flex;
    justify-content: center;

    width: 100%;
  }
  .modal-form__btn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 60px;

    color: #fff;
    text-align: center;
    font-family: Inter, sans-serif;
    @include adaptiv-font(17, 14);
    font-style: normal;
    font-weight: 500;
    line-height: 65.795px; /* 469.963% */
    text-transform: uppercase;

    border-radius: 20px;
    border: 2px solid #fff;

    background: #29344d;

    cursor: pointer;
  }
  .modal-form__btn-active {
    transition: width 0.6s ease-in-out 0s, background-color 0.6s ease-in-out 0s,
      border-radius 0.1s ease-in-out 0.5s;
    width: 60px;
    border-radius: 50%;
    background-color: green;
  }
}

.checkmark {
  display: none;
}

.show-checkmark {
  display: block;
}

.checkmark {
  height: 55px;
  svg {
    width: 24px;
    height: auto;
    padding: 0;
    padding-left: 1px;
  }
}

.checkmark {
  svg {
    .path {
      stroke-linecap: round;
      stroke-dasharray: 30;
      stroke-dashoffset: 30;
      animation: dash 0.5s ease forwards;
      -webkit-animation: dash 0.5s ease forwards;
    }
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.main-circle {
  display: none;

  width: 40px;
  height: 40px;
  border: 2px solid #fff;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  position: relative;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  animation: rotate 2s infinite;
}
.main-circle-active {
  display: flex;
}
.main-circle:before {
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  border: 2px solid transparent;
  border-right: 2px solid #fff;
  transform: rotate(40deg);
  border-radius: 50%;
}
.green-circle {
  animation: rotate 2s infinite 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  transform: rotate(-20deg);
  border-radius: 50%;
  position: relative;
}
.green-circle:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  border: 2px solid transparent;
  border-right: 2px solid #fff;
  transform: rotate(60deg);
}
.brown-circle {
  animation: rotate 2s infinite 0.6s;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  transform: rotate(-20deg);
  border-radius: 50%;
}
@keyframes rotate {
  from {
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1000px) {
  .modal__close-btn {
    display: block;
  }
}

@media (max-width: 500px) {
  .modal__form-container {
    padding: 75px 25px 100px 25px;
  }
  .modal-form__body {
    width: 300px;
  }
}
</style>
