<template>
  <router-link to="/shop/3">
    <div
      class="banner__catalog"
      @mouseover="isHoverCard = true"
      @mouseout="isHoverCard = false"
    >
      <div class="banner__catalog-body">
        <p class="banner__catalog-body_title">Product Catalog</p>
        <div
          class="banner__catalog-body_btn"
          :class="{ 'banner__catalog-body_btn-active': isHoverSmallBtn }"
          @mouseover="isHoverSmallBtn = true"
          @mouseout="isHoverSmallBtn = false"
        >
          Смотреть каталог

          <svg
            class="banner__arrow"
            :class="{ 'banner__arrow-active': isHoverSmallBtn }"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Group">
              <path
                id="Vector"
                d="M14.1378 12.9801L8.9436 7.78589L10.4274 6.30212L17.1053 12.9801L10.4274 19.6581L8.9436 18.1743L14.1378 12.9801Z"
              />
            </g>
          </svg>
        </div>
      </div>
      <div class="banner__catalog-arrow_container">
        <img :src="bigArrowImg" alt="" />
      </div>
      <div
        class="banner__catalog-gradient"
        :class="{ 'banner__catalog-gradient-active': isHoverCard }"
      ></div>
    </div>
  </router-link>
</template>

<script>
export default {
  data() {
    return {
      bigArrowImg: require("@/assets/img/banners/banner_catalog/big_arrow.svg"),
      isHoverSmallBtn: false,
      isHoverCard: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.banner__catalog {
  position: relative;
  display: flex;

  width: 100%;
  height: 100%;

  border-radius: 20px;
  background: #282728;

  cursor: pointer;
}

.banner__catalog-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 32px;

  margin-left: 60px;
}
.banner__catalog-body_title {
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 124%;
  letter-spacing: -1.08px;
}
.banner__catalog-body_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 15px;

  max-width: 240px;
  width: 100%;
  height: 60px;

  border-radius: 40px;
  border: 1px solid #fff;

  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 17.379px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.379px; /* 100% */

  cursor: pointer;

  transition: all 0.2s linear 0.1s;
}
.banner__catalog-body_btn-active {
  transition: all 0.2s linear 0.1s;

  color: #282728;
  border: 1px solid #fff;
  background-color: #fff;
}
.banner__arrow {
  transition: all 0.2s linear 0.1s;
  fill: #fff;
}
.banner__arrow-active {
  transition: all 0.2s linear 0.1s;
  fill: #282728;
}

.banner__catalog-arrow_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  flex-grow: 1;

  margin-right: 15px;
}

.banner__catalog-gradient {
  position: absolute;
  top: 0;
  right: 0;

  width: 32%;
  height: 100%;

  border-radius: 0px 20px 20px 0px;
  opacity: 0.25;
  background: linear-gradient(270deg, #fff 0, rgba(255, 255, 255, 0) 88.53%);
  transition: all 0.2s linear 0.1s;
}
.banner__catalog-gradient-active {
  transition: all 0.2s linear 0.1s;
  width: 50%;
}

@media (max-width: 1170px) {
  .banner__catalog {
    border-radius: 0;
  }
  .banner__catalog-gradient {
    border-radius: 0;
  }
}

@media (max-width: 762px) {
  .banner__catalog {
    justify-content: center;
  }
  .banner__catalog-body {
    align-items: center;
  }
  .banner__catalog-arrow_container,
  .banner__catalog-gradient {
    display: none;
  }
  .banner__catalog-body {
    margin: 0;
  }
}
</style>
