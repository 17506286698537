<template>
  <div class="marquee-container">
    <div class="marquee-wrapper">
      <div class="marquee-content-first">
        <img
          v-for="(item, index) in projectImg"
          :key="index"
          :src="require('@/assets/img/banners/banner_about/' + item.img)"
          alt=""
        />
      </div>
      <div class="marquee-content-second">
        <img
          v-for="(item, index) in projectImg"
          :key="index"
          :src="require('@/assets/img/banners/banner_about/' + item.img)"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projectImg: [
        {
          img: "1.png",
        },
        {
          img: "2.png",
        },
        {
          img: "3.png",
        },
        {
          img: "4.png",
        },
        {
          img: "5.png",
        },
        {
          img: "6.png",
        },
        {
          img: "7.png",
        },
        {
          img: "8.png",
        },
        {
          img: "9.png",
        },
        {
          img: "10.png",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.marquee-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100px;
}

.marquee-wrapper {
  display: flex;
}

.marquee-content-first {
  animation: marquee-scroll-first 15s linear infinite;
}
.marquee-content-second {
  animation: marquee-scroll-second 15s linear infinite;
}
.marquee-content-first,
.marquee-content-second {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
}

.marquee-content-first,
.marquee-content-second {
  img {
    margin-right: 33px;
  }
}

@keyframes marquee-scroll-first {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-2694px);
  }
}
@keyframes marquee-scroll-second {
  0% {
    transform: translateX(2694px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
