<template>
  <div
    class="banner__btn"
  >
    <p class="banner__btn-txt"><slot /></p>
    <svg
      class="banner__arrow"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <path
          id="Vector"
          d="M14.1378 12.9801L8.9436 7.78589L10.4274 6.30212L17.1053 12.9801L10.4274 19.6581L8.9436 18.1743L14.1378 12.9801Z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
$maxWidth: 1920;
@mixin adaptiv-font($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $maxWidth: $maxWidth - 375;
  font-size: calc(
    #{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth})
  );
	@media (min-width: 1920px) {
		font-size: $pcSize + px;
	}
}

.banner__btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;

  padding: 18px 30px;

  border-radius: 30px;
  border: 1px solid #29344d;

  cursor: pointer;

  transition: all 0.2s ease-in-out 0s;

  &:hover {
    transition: all 0.2s ease-in-out 0s;

    border: 1px solid #29344d;
    background: #29344d;

    .banner__btn-txt {
      color: #ffffff;
    }
    .banner__arrow {
      fill: #ffffff;
    }
  }
}
.banner__btn-txt {
  color: #29344d;
  font-family: Inter, sans-serif;
  @include adaptiv-font(16, 12);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.banner__arrow {
  fill: #29344d; // #FFFFFF #29344d
}
.active__filter-item__btn {
  transition: all 0.2s ease-in-out 0s;

  border: 1px solid #29344d;
  background: #29344d;

  .banner__btn-txt {
    color: #ffffff;
  }
  .banner__arrow {
    fill: #ffffff;
  }
}

@media (max-width: 762px) {
  .banner__btn {
    padding: 13px 22px;
  }
}

@media (max-width: 500px) {
  .banner__btn {
    padding: 10px 18px;
  }
}
</style>
