<template>
  <router-link :to="'/mountain'">
    <div
      class="banner__mountain"
      @mouseover="isActive = true"
      @mouseout="isActive = false"
    >
      <div class="cursor-circle">ПЕРЕЙТИ</div>
      <img
        class="banner__mountain-img"
        :class="{ 'banner__mountain-img_active': isActive }"
        :src="mountainImg"
        alt=""
      />
      <p class="banner__mountain-text">
        Пожаротушение горно - карьерной техники
      </p>
    </div>
  </router-link>
</template>

<script>
export default {
  data() {
    return {
      mountainImg: require("@/assets/img/banners/banner_mountain/mountain.png"),
      isActive: false,
    };
  },
  mounted() {
    const wrapper = document.querySelector(".banner__mountain");
    const layers = document.querySelector(".cursor-circle");

    const handleParallax = (evt) => {
      //размер области просмотра
      const parallaxLeftOffset = wrapper.getBoundingClientRect().left;
      const parallaxTopOffset = wrapper.getBoundingClientRect().top;

      // координаты экрана
      let rect = wrapper.getBoundingClientRect();
      let x = evt.clientX - rect.left;
      let y = evt.clientY - rect.top;

      layers.setAttribute("style", `transform: translate(${x+25}px, ${y+20}px);`);
    };

    const reset = () => {
      layers.removeAttribute("style");
    };

    wrapper.addEventListener("mousemove", handleParallax);
    wrapper.addEventListener("mouseout", reset);
  },
};
</script>

<style lang="scss" scoped>
.banner__mountain {
  position: relative;
  overflow: hidden;

  height: 100%;

  border-radius: 20px;

  cursor: pointer;
}
.cursor-circle {
  position: absolute;
  top: -50px;
  left: -50px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 50px;
  height: 50px;

  background-color: rgba(0, 0, 0, 0.295);
  border-radius: 50%;

  z-index: 1;

  color: #fff;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
}
.banner__mountain-img {
  width: 100%;
  height: 100%;

  object-fit: cover;

  transition: all 0.2s linear 0s;
}
.banner__mountain-img_active {
  transition: all 0.2s linear 0s;

  transform: scale(1.05);
}
.banner__mountain-text {
  position: absolute;
  bottom: 30px;
  left: 30px;

  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 100% */
}
@media (max-width: 1170px) {
  .banner__mountain {
    border-radius: 0;
  }
}
@media (max-width: 762px) {
  .banner__mountain-text {
    left: 20px;
    right: 20px;
    font-size: 17px;
  }
}
</style>
