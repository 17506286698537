<template>
  <div class="banners__container container">
    <div class="first-row">
      <app-banners-request-vue
        @openBannerForm="openBannerForm"
        class="request"
      ></app-banners-request-vue>
      <app-banners-catalog-links-vue
        class="catalog-links"
      ></app-banners-catalog-links-vue>
    </div>
    <div class="second-row">
      <app-catalog-vue class="catalog"></app-catalog-vue>
      <app-mountain-vue class="mountain"></app-mountain-vue>
    </div>
    <app-about-vue class="about"></app-about-vue>
    <app-sub-app-vue @closeForm="isOpenForm = false"></app-sub-app-vue>
  </div>
</template>

<script>
import AppBannersCatalogLinksVue from "./AppBannersCatalogLinks.vue";
import AppBannersRequestVue from "./AppBannersRequest.vue";
import AppCatalogVue from "./AppCatalog.vue";
import AppMountainVue from "./AppMountain.vue";
import AppAboutVue from "./AppAbout.vue";
import AppBannerSwiper from "./AppBannerSwiper.vue";
import AppSubAppVue from "../ModalWindows/AppSubApp.vue";
import { computed } from "vue";

export default {
  data() {
    return {
      isOpenForm: false,
    };
  },
  provide() {
    return {
      isOpenForm: computed(() => this.isOpenForm),
    };
  },
  methods: {
    openBannerForm() {
      this.isOpenForm = true;
    },
  },
  components: {
    AppBannersRequestVue,
    AppBannersCatalogLinksVue,
    AppCatalogVue,
    AppMountainVue,
    AppAboutVue,
    AppBannerSwiper,
    AppSubAppVue,
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 1520px;

  margin: 0 auto;
}

.banners__container {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
.first-row,
.second-row {
  display: flex;
  column-gap: 30px;
}
.request {
  max-width: 866px;
  width: 100%;
  height: 746px;
}
.catalog-links {
  max-width: 624px;
  width: 100%;
  height: 746px;
}

.catalog {
  max-width: 626px;
  width: 100%;
  height: 372px;
}
.mountain {
  max-width: 864px;
  width: 100%;
  height: 372px;
}
.about {
  width: 100%;
}

@media (max-width: 1560px) {
  .banners__container {
    padding: 0 20px;
  }
}
@media (max-width: 1170px) {
  .banners__container {
    padding: 0;
  }
  .first-row,
  .second-row {
    flex-direction: column;

    gap: 0;
    padding: 0;
  }
  .banners__container {
    gap: 0;
  }
  .request {
    max-width: 100%;
    width: 100%;
    height: 500px;
  }
  .catalog-links {
    max-width: 100%;
    width: 100%;
    height: 500px;
  }

  .catalog {
    max-width: 100%;
    width: 100%;
    height: 372px;
  }
  .mountain {
    max-width: 100%;
    width: 100%;
    height: 372px;
  }
}
@media (max-width: 1000px) {
  .about {
    height: auto;
  }
}

@media (max-width: 500px) {
  .catalog-links {
    height: 600px;
  }
  .request {
    height: 450px;
  }
}
</style>
