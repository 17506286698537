<template>
  <div class="banner__request">
    <p class="banner__title">SHTAR SYSTEM</p>
    <ul class="banner__body">
      <li>{{ mainPage }}</li>
      <li>{{ mainPageTwo }}</li>
    </ul>
    <div class="banner__footer-wrapper">
      <div
        class="banner__footer"
        @mouseover="isHoverRequest = true"
        @mouseout="isHoverRequest = false"
        @click="this.$emit('openBannerForm')"
      >
        <div class="banner__btn-first" :class="{ shake_btn: isHoverRequest }">
          <img :src="arrowImg" alt="" />
        </div>
        <div class="banner__btn-second">
          <img :src="arrowImg" alt="" />
          <p class="banner__btn-second_text">ОСТАВИТЬ ЗАЯВКУ</p>
        </div>
      </div>
    </div>

    <img class="banner__decor-img" :src="iconImg" alt="" />
  </div>
</template>

<script>
export default {
  inject: ["pageContentt"],
  data() {
    return {
      arrowImg: require("@/assets/img/banners/banner_request/arrow.svg"),
      iconImg: require("@/assets/img/banners/banner_request/icon.svg"),
      isHoverRequest: false,
      mainPage: this.pageContentt.mainPage,
      mainPageTwo: this.pageContentt.mainPageTwo,
    };
  },
  watch: {
    pageContentt() {
      this.mainPage = this.pageContentt.mainPage;
      this.mainPageTwo = this.pageContentt.mainPageTwo;
    },
  },
};
</script>

<style lang="scss" scoped>
$maxWidth: 1920;
@mixin adaptiv-font($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $maxWidth: $maxWidth - 375;
  font-size: calc(
    #{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth})
  );
  @media (min-width: 1920px) {
    font-size: $pcSize + px;
  }
}
@mixin adaptiv-line-height($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $maxWidth: $maxWidth - 375;
  line-height: calc(
    #{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth})
  );
  @media (min-width: 1920px) {
    line-height: $pcSize + px;
  }
}

.banner__request {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 45px;

  box-sizing: border-box;
  max-width: 866px;

  padding: 70px 0 0 60px;

  border-radius: 20px;
  background: #e6e6e6;

  &::after {
    position: absolute;
    content: "";

    top: -14px;
    left: 30px;

    width: 104%;
    height: 108%;

    border-radius: 20px;
    border: 1px solid #c0c0c0;

    z-index: -1;
  }
}
.banner__title {
  color: #29344d;
  font-family: Orbitron, sans-serif;
  @include adaptiv-font(115, 50);
  font-style: normal;
  font-weight: 600;
  line-height: 116.5px;
  @include adaptiv-line-height(115, 50);
}
.banner__body {
  margin-left: 35px;

  color: #282728;
  font-family: Inter, sans-serif;
  @include adaptiv-font(24, 10);
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  @include adaptiv-line-height(38, 20);
  text-transform: uppercase;

  list-style-type: disc;
}
.banner__footer {
  display: inline-flex;

  cursor: pointer;

  transition: all 0.2s linear 0.1s;
  &:hover {
    transform: translateX(10px);
    transition: all 0.2s linear 0.1s;
  }
}

.banner__footer-wrapper {
  z-index: 1;
}
.banner__btn-first {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;

  border-radius: 50%;
  background: #29344d;

  transition: all 0.2s linear 0s;
}
.banner__btn-second {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 25px;

  width: 236px;
  height: 60px;

  overflow: hidden;

  border-radius: 40px;
  background: #282728;

  &::after {
    content: "";
    display: block;
    width: 20px;
    height: 200px;
    margin-left: 50px;
    background: #fff;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0.2) 100%
    );

    backdrop-filter: blur(5px);
    left: -40px;
    top: -100px;
    z-index: 1;
    transform: rotate(45deg);
    position: absolute;
    animation: movingFlare 3s ease-in-out 0.05s infinite;
  }

  @keyframes movingFlare {
    0% {
      left: -30px;
      margin-left: 0px;
    }
    30% {
      left: 110%;
      margin-left: 80px;
    }
    100% {
      left: 110%;
      margin-left: 80px;
    }
  }
}
.banner__btn-second_text {
  color: #fff;
  text-align: center;
  font-family: Inter, sans-serif;
  @include adaptiv-font(17, 15);
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-transform: lowercase;
}
.banner__decor-img {
  position: absolute;
  right: 35px;
  bottom: 25px;
}

.shake_btn {
  animation-name: shake;
  animation-duration: 3s;
  animation-delay: 0.2s;
}

@keyframes shake {
  from {
    transform: translateX(0px);
  }

  20% {
    transform: translateX(-10px);
  }

  30% {
    transform: translateX(-5px);
  }

  40% {
    transform: translateX(-10px);
  }

  50% {
    transform: translateX(-5px);
  }

  60% {
    transform: translateX(-10px);
  }

  70% {
    transform: translateX(-5px);
  }

  80% {
    transform: translateX(-10px);
  }

  90% {
    transform: translateX(-5px);
  }

  to {
    transform: translateX(0px);
  }
}

@media (max-width: 1170px) {
  .banner__request {
    border-radius: 0;
    &::after {
      display: none;
    }
  }
}
@media (max-width: 762px) {
  .banner__request {
    align-items: center;

    padding: 60px 0;
  }
  .banner__title {
    text-align: center;
  }
  .banner__body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .banner__btn-first {
    display: none;
  }
  .banner__decor-img {
    width: 156px;
    height: 200px;

    right: 25px;
    bottom: 10px;
  }
}
</style>
