import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const ShopView = () => import("../views/ShopView.vue");
const ProductView = () => import("../views/ProductView.vue");
const SectionView = () => import("../views/SectionView.vue");
const MountainView = () => import("../views/MountainView.vue");

const routes = [
  {
    path: "/home",
    alias: "/",
    name: "home",
    component: HomeView,  
    
  },
  {
    path: "/shop/:shopId?",
    name: "shop",
    component: ShopView,
  },
  {
    path: "/product/:productId?",
    name: "product",
    component: ProductView,
  },
  {
    path: "/section/:sectionId?",
    name: "section",
    component: SectionView,
  },
  {
    path: "/mountain",
    name: "mountain",
    component: MountainView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            el: to.hash,
            behavior: "smooth",
            top: 120,
          });
        }, 220);
      });
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ left: 0, top: 0 });
        }, 200);
      });
    }
  },
  
});

export default router;
