<template>
  <div id="clients" class="clients__container container">
    <p class="clients__title">НАМ ДОВЕРЯЮТ</p>
    <div class="clients__body">
      <img
        v-for="(item, idx) in clientsImg"
        :key="idx"
        :src="item.img"
        alt=""
      />
    </div>
    <div class="marquee-container">
      <div class="marquee-wrapper">
        <div class="marquee-content-first">
          <img
 v-for="(item, index) in clientsImg"
 :key="index"
 :src="item.img"
 alt=""
          />
        </div>
        <div class="marquee-content-second">
          <img
 v-for="(item, index) in clientsImg"
 :key="index"
 :src="item.img"
 alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      clientsImg: [
        {
          img: require("@/assets/img/clients/1.png"),
        },
        {
          img: require("@/assets/img/clients/2.png"),
        },
        {
          img: require("@/assets/img/clients/3.png"),
        },
        {
          img: require("@/assets/img/clients/4.png"),
        },
        {
          img: require("@/assets/img/clients/5.png"),
        },
        {
          img: require("@/assets/img/clients/6.png"),
        },
        {
          img: require("@/assets/img/clients/7.png"),
        },
        {
          img: require("@/assets/img/clients/8.png"),
        },
        {
          img: require("@/assets/img/clients/9.png"),
        },
        {
          img: require("@/assets/img/clients/10.png"),
        },
        {
          img: require("@/assets/img/clients/11.png"),
        },
        {
          img: require("@/assets/img/clients/12.png"),
        },
        {
          img: require("@/assets/img/clients/13.png"),
        },
        {
          img: require("@/assets/img/clients/14.png"),
        },
        {
          img: require("@/assets/img/clients/15.png"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
$maxWidth: 1920;
@mixin adaptiv-font($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $maxWidth: $maxWidth - 375;
  font-size: calc(
    #{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth})
  );
	@media (min-width: 1920px) {
		font-size: $pcSize + px;
	}
}

@mixin adaptiv-line-height($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $maxWidth: $maxWidth - 375;
  line-height: calc(
    #{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth})
  );
    	@media (min-width: 1920px) {
		line-height: $pcSize + px;
	}
}

.container {
  max-width: 1520px;

  margin: 0 auto;
}
.marquee-container {
  display: none;
}
.clients__container {
  display: flex;
  flex-direction: column;
  row-gap: 100px;
}
.clients__title {
  color: #29344d;
  font-family: Inter, sans-serif;
  @include adaptiv-font(60, 24);
  font-style: normal;
  font-weight: 600;
  line-height: 83.158px; /* 138.597% */
}
.clients__body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 71px;
  column-gap: 60px;
}

@media (max-width: 1560px) {
  .clients__container {
    padding: 0 20px;
  }
  .clients__body {
    display: none;
  }
  .marquee-container {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100px;
  }

  .marquee-wrapper {
    display: flex;
  }

  .marquee-content-first {
    animation: marquee-scroll-first 15s linear infinite;
  }
  .marquee-content-second {
    animation: marquee-scroll-second 15s linear infinite;
  }
  .marquee-content-first,
  .marquee-content-second {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
  }

  .marquee-content-first,
  .marquee-content-second {
    img {
      margin-right: 50px;
    }
  }

  @keyframes marquee-scroll-first {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-2921px);
    }
  }
  @keyframes marquee-scroll-second {
    0% {
      transform: translateX(2921px);
    }
    100% {
      transform: translateX(0);
    }
  }
}

// @media (max-width: 1370px) {
// }
</style>
