<template>
  <div class="banner__catalog-links">
    <p class="banner__title">Пожаротушение</p>
    <div class="banner__body">
      <AppBannersCatalogLinksBtnVue
        >Структурированная кабельная система</AppBannersCatalogLinksBtnVue
      >
      <AppBannersCatalogLinksBtnVue
        >Охранная сигнализация</AppBannersCatalogLinksBtnVue
      >
      <AppBannersCatalogLinksBtnVue>Оповещение</AppBannersCatalogLinksBtnVue>
      <AppBannersCatalogLinksBtnVue
        >Видеонаблюдение
      </AppBannersCatalogLinksBtnVue>
      <AppBannersCatalogLinksBtnVue>Пожаротушение</AppBannersCatalogLinksBtnVue>
      <AppBannersCatalogLinksBtnVue
        >Пожарная сигнализация</AppBannersCatalogLinksBtnVue
      >
    </div>
  </div>
</template>

<script>
import AppBannersCatalogLinksBtnVue from "./AppBannersCatalogLinksBtn.vue";

export default {
  components: { AppBannersCatalogLinksBtnVue },
};
</script>

<style lang="scss" scoped>
$maxWidth: 1920;
@mixin adaptiv-font($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $maxWidth: $maxWidth - 375;
  font-size: calc(
    #{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth})
  );
	@media (min-width: 1920px) {
		font-size: $pcSize + px;
	}
}
.banner__catalog-links {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  box-sizing: border-box;

  padding: 30px 0 0 30px;

  border-radius: 20px;
  background: linear-gradient(143deg, #eee 2.99%, #d8d7d7 82.58%);
}
.banner__title {
  position: absolute;
  top: 30px;
  left: 30px;

  color: #282728;
  font-family: Inter, sans-serif;
  @include adaptiv-font(20, 12);
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 100% */
}
.banner__body {
  display: flex;
  flex-wrap: wrap;
  column-gap: 25px;
  row-gap: 30px;
}

@media (max-width: 1170px) {
  .banner__catalog-links {
    border-radius: 0;
  }
}

@media (max-width: 500px) {
  .banner__catalog-links{
      padding: 30px 20px 0 20px;
  }
}
</style>
