<template>
  <app-header-vue @clickBtnCatalog="toggleCatalog"></app-header-vue>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" :key="$route.path" />
    </transition>
  </router-view>
  <app-footer-vue @toServices="toServices"></app-footer-vue>
</template>

<script>
import AppHeaderVue from "@/components/Header/AppHeader.vue";
import AppFooterVue from "@/components/Footer/AppFooter.vue";

import { computed } from "vue";


export default {
  data() {
    return {
      isClickBtnCatalog: true,
      activeService: null,


    };
  },
  provide() {
    return {
      isClickBtnCatalog: computed(() => this.isClickBtnCatalog),
      activeServiceS: computed(() => this.activeService),

    };
  },


  methods: {
    toggleCatalog() {
      this.isClickBtnCatalog = !this.isClickBtnCatalog;
    },
    toServices(idx) {
      this.activeService = idx;
    },
  },
  mounted() {},
  components: { AppHeaderVue, AppFooterVue },
};
</script>

<style lang="scss">
body {
  background: #f2f2f2;
}
a {
  display: block;
  text-decoration: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
