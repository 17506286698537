<template>
  <div
    class="container-position"
    :class="{
      'container-position__close': isCloseCatalog,
    }"
    ref="filter"
  >
    <div class="filter__container">
      <div class="filter__wrapper">
        <div class="filter__group-link">
          <div class="filter__title" @click="openFirstList">
            <p>Пожарная сигнализация</p>
            <svg
              class="filter__title-btn"
              :class="{ deg180: isOpenFirstList }"
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="15.0574"
                width="14.5696"
                height="14.5696"
                rx="7.28482"
                transform="rotate(89.4942 15.0574 0)"
                fill="#29344D"
              />
              <path
                d="M10.8054 5.83179L7.85037 8.83948L4.84267 5.88443"
                stroke="white"
                stroke-width="0.745373"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="filter__item-container" ref="firstItemContainer">
            <router-link @click="resetPagination" to="/shop/3">
              <div class="filter__item">
                Адресные сисистемы ОПС и противопожарной автоматики
              </div>
            </router-link>

            <router-link @click="resetPagination" to="/shop/4">
              <div class="filter__item">
                Приемно-контрольные приборы и приборы речевого оповещения
                комплекса РУБЕЖ
              </div>
            </router-link>
            <router-link @click="resetPagination" to="/shop/5">
              <div class="filter__item">
                Приборы управления системами пожаротушения и автоматикой РУБЕЖ
              </div>
            </router-link>
            <router-link @click="resetPagination" to="/shop/6">
              <div class="filter__item">
                Пульты и клавиатуры управления, блоки индикации, преобразователи
                интерфейсов
              </div>
            </router-link>
            <router-link @click="resetPagination" to="/shop/8">
              <div class="filter__item">Средства автоматизации Рубеж</div>
            </router-link>
            <router-link @click="resetPagination" to="/shop/9">
              <div class="filter__item">
                Аналоговые извещатели и световые табло
              </div>
            </router-link>
            <router-link @click="resetPagination" to="/shop/10">
              <div class="filter__item">
                Источники резервного питания и аккумуляторы
              </div>
            </router-link>
            <router-link @click="resetPagination" to="/shop/11">
              <div class="filter__item">Радиокальная система Рубеж</div>
            </router-link>
          </div>
        </div>
        <div class="filter__group-link">
          <div class="filter__title" @click="openSecondList">
            <p>Система контроля и управления доступом (СКУД)</p>
            <svg
              class="filter__title-btn"
              :class="{ deg180: isOpenSecondList }"
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="15.0574"
                width="14.5696"
                height="14.5696"
                rx="7.28482"
                transform="rotate(89.4942 15.0574 0)"
                fill="#29344D"
              />
              <path
                d="M10.8054 5.83179L7.85037 8.83948L4.84267 5.88443"
                stroke="white"
                stroke-width="0.745373"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="filter__item-container" ref="secondItemContainer">
            <router-link @click="resetPagination" to="/shop/17">
              <div class="filter__item">Система контроля доступа Рубеж</div>
            </router-link>
          </div>
        </div>

        <div class="filter__group-link">
          <div class="filter__title" @click="openThirdList">
            <p>Спецавтоматика</p>
            <svg
              class="filter__title-btn"
              :class="{ deg180: isOpenThirdList }"
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="15.0574"
                width="14.5696"
                height="14.5696"
                rx="7.28482"
                transform="rotate(89.4942 15.0574 0)"
                fill="#29344D"
              />
              <path
                d="M10.8054 5.83179L7.85037 8.83948L4.84267 5.88443"
                stroke="white"
                stroke-width="0.745373"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="filter__item-container" ref="thirdItemContainer">
            <router-link @click="resetPagination" to="/section/19">
              <div class="filter__item">Оросители</div>
            </router-link>
            <router-link @click="resetPagination" to="/section/20">
              <div class="filter__item">Узлы управления</div>
            </router-link>
            <router-link @click="resetPagination" to="/section/21">
              <div class="filter__item">Изделия пожарной автоматики</div>
            </router-link>
          </div>
        </div>

        <div class="filter__group-link">
          <div class="filter__title" @click="openFourthList">
            <p>Пожаротушение</p>
            <svg
              class="filter__title-btn"
              :class="{ deg180: isOpenFourthList }"
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="15.0574"
                width="14.5696"
                height="14.5696"
                rx="7.28482"
                transform="rotate(89.4942 15.0574 0)"
                fill="#29344D"
              />
              <path
                d="M10.8054 5.83179L7.85037 8.83948L4.84267 5.88443"
                stroke="white"
                stroke-width="0.745373"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="filter__item-container-btn" ref="fourthItemContainer">
            <router-link @click="resetPagination" to="/shop/12">
              <AppBannersCatalogLinksBtnVue
                >Газовое</AppBannersCatalogLinksBtnVue
              >
            </router-link>
            <router-link @click="resetPagination" to="/section/13">
              <AppBannersCatalogLinksBtnVue
                >Порошковое</AppBannersCatalogLinksBtnVue
              ></router-link
            >
            <router-link @click="resetPagination" to="/section/14">
              <AppBannersCatalogLinksBtnVue
                >Водяное</AppBannersCatalogLinksBtnVue
              >
            </router-link>
            <router-link @click="resetPagination" to="/section/15">
              <AppBannersCatalogLinksBtnVue
                >Пенное</AppBannersCatalogLinksBtnVue
              >
            </router-link>
            <router-link @click="resetPagination" to="/shop/16">
              <AppBannersCatalogLinksBtnVue
                >Кухонное</AppBannersCatalogLinksBtnVue
              >
            </router-link>
            <router-link @click="resetPagination" to="/shop/24">
              <AppBannersCatalogLinksBtnVue
                >Горно - карьерное
              </AppBannersCatalogLinksBtnVue>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="bgr__close" @click="toggelCatalog"></div>
  </div>
</template>

<script>
import AppBannersCatalogLinksBtnVue from "../Banners/AppBannersCatalogLinksBtn.vue";
import { useCounterStore } from "../../store/index";

export default {
  inject: ["isClickBtnCatalog"],
  data() {
    return {
      isOpenFirstList: true,
      isOpenSecondList: true,
      isOpenThirdList: true,
      isOpenFourthList: true,
      isCloseCatalog: true,
    };
  },
  mounted() {
    let x;
    this.$refs.filter.addEventListener(
      "touchstart",
      (e) => (x = e.changedTouches[0].clientX),
      { passive: true }
    );
    this.$refs.filter.addEventListener(
      "touchend",
      (e) => e.changedTouches[0].clientX - x < -50 && this.toggelCatalog(),
      { passive: true }
    );

    this.$refs.firstItemContainer.style.maxHeight =
      this.$refs.firstItemContainer.scrollHeight + "px";
    this.$refs.secondItemContainer.style.maxHeight =
      this.$refs.secondItemContainer.scrollHeight + "px";
    this.$refs.thirdItemContainer.style.maxHeight =
      this.$refs.thirdItemContainer.scrollHeight + "px";
    this.$refs.fourthItemContainer.style.maxHeight =
      this.$refs.fourthItemContainer.scrollHeight + "px";
  },
  watch: {
    isClickBtnCatalog() {
      this.toggelCatalog();
    },
  },
  methods: {
    openFirstList() {
      this.isOpenFirstList = !this.isOpenFirstList;
      if (this.isOpenFirstList) {
        this.$refs.firstItemContainer.style.maxHeight =
          this.$refs.firstItemContainer.scrollHeight + "px";
      } else {
        this.$refs.firstItemContainer.style.maxHeight = "0px";
      }
    },
    openSecondList() {
      this.isOpenSecondList = !this.isOpenSecondList;
      if (this.isOpenSecondList) {
        this.$refs.secondItemContainer.style.maxHeight =
          this.$refs.secondItemContainer.scrollHeight + "px";
      } else {
        this.$refs.secondItemContainer.style.maxHeight = "0px";
      }
    },
    openThirdList() {
      this.isOpenThirdList = !this.isOpenThirdList;
      if (this.isOpenThirdList) {
        this.$refs.thirdItemContainer.style.maxHeight =
          this.$refs.thirdItemContainer.scrollHeight + "px";
      } else {
        this.$refs.thirdItemContainer.style.maxHeight = "0px";
      }
    },
    openFourthList() {
      this.isOpenFourthList = !this.isOpenFourthList;
      if (this.isOpenFourthList) {
        this.$refs.fourthItemContainer.style.maxHeight =
          this.$refs.fourthItemContainer.scrollHeight + "px";
      } else {
        this.$refs.fourthItemContainer.style.maxHeight = "0px";
      }
    },
    toggelCatalog() {
      this.isCloseCatalog = !this.isCloseCatalog;
    },
    resetPagination() {
      useCounterStore().setActivePage(1);
    },
  },
  components: { AppBannersCatalogLinksBtnVue },
};
</script>

<style lang="scss" scoped>
.container-position {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 26;
  transform: translate(0px);
  transition: all 0.3s ease-in-out 0s;
}

.container-position__close {
  transform: translate(-100%);
  transition: all 0.3s ease-in-out 0s;
}

.bgr__close {
  width: 100%;
  height: 100%;
}
.filter__container {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;

  box-sizing: border-box;
  width: 470px;
  height: 100vh;

  overflow-y: scroll;

  border-radius: 0px 40px 40px 0px;
  background: #fafafa;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #fafafa;
    margin: 30px 0;
    border-radius: 40px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cacaca;
    width: 3px;

    border-radius: 40px;
  }
}
.filter__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 25px;

  width: 344px;

  margin: 65px 0;
}
.filter__group-link {
  display: flex;
  flex-direction: column;
  row-gap: 25px;

  width: 100%;
}
.filter__title {
  display: flex;
  column-gap: 30px;
  p {
    color: #17171b;
    font-family: Inter, sans-serif;
    font-size: 12.435px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .filter__title-btn {
    transition: all 0.2s linear 0s;
  }
  cursor: pointer;
}
.deg180 {
  transform: rotate(180deg);
  transition: all 0.2s linear 0s;
}
.filter__item-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  overflow: hidden;

  transition: all 0.2s linear 0s;
}
.filter__item-container-btn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 12px;

  margin-top: 15px;
  margin-bottom: 65px;

  overflow: hidden;

  transition: all 0.2s linear 0s;
}
.filter__item {
  width: 100%;

  padding: 15px 0;

  color: #626262;
  font-family: Inter, sans-serif;
  font-size: 10.881px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.141px; /* 157.536% */

  border-bottom: solid 1px #d7d7e0;

  cursor: pointer;
  transition: all 0.1s linear 0s;

  &:hover {
    color: #000;
    font-weight: 700;
    transition: all 0.1s linear 0s;
  }
}
@media (max-width: 762px) {
  .filter__container {
    max-width: 90vw;
  }
  .filter__wrapper {
    padding: 0 20px;
  }
}
</style>
