<template>
  <div>
    <app-banners-vue class="banners"></app-banners-vue>
    <app-home-swiper-vue
      class="home-swiper"
      v-if="loadedShopTable"
      :cardsArray="cards"
    ></app-home-swiper-vue>
    <app-services-vue class="services"></app-services-vue>
    <app-clients-vue class="clients"></app-clients-vue>
    <app-home-from-vue></app-home-from-vue>

    <app-filter-vue></app-filter-vue>
  </div>
</template>

<script>
import AppBannersVue from "@/components/Banners/AppBanners.vue";
import AppHomeSwiperVue from "@/components/HomeSwiper/AppHomeSwiper.vue";
import AppServicesVue from "@/components/Services/AppServices.vue";
import AppClientsVue from "@/components/Сlients/AppClients.vue";
import AppHomeFromVue from "@/components/HomeFrom/AppHomeFrom.vue";

import AppFilterVue from "@/components/Filter/AppFilter.vue";
import axios from "axios";
import { computed } from "vue";
export default {
  data() {
    return {
      loadedShopTable: false,
      cards: [],
      pageContent: {},
    };
  },

  provide() {
    return {
      pageContentt: computed(() => this.pageContent),
    };
  },

  components: {
    AppBannersVue,
    AppHomeSwiperVue,
    AppServicesVue,
    AppClientsVue,
    AppHomeFromVue,

    AppFilterVue,
  },
  methods: {},

  mounted() {
    let idPage = this.$route.fullPath;
    this.$router.push(`${idPage}`);
    const constThis = this;
    async function getInfo() {
      constThis.loadedShopTable = false;

      const response = await axios.get(
        `https://bot.shtar.uz/backend/api/v1/product/productList/25/`
      );
      return response.data;
    }
    getInfo().then((result) => {
      this.cards = result.map((product) => ({
        ...product,
        isHoverIcon: false,
        isOpenImg: false,
      }));
      constThis.loadedShopTable = true;
    });

    async function getPageContent() {
      const response = await axios.get(
        `https://bot.shtar.uz/backend/api/v1/site/content/2/`
      );

      return response.data;
    }
    getPageContent().then((result) => {
      this.pageContent = result;
    });
  },
};
</script>

<style lang="scss" scoped>
body {
  position: relative;
}
.banners {
  margin-bottom: 100px;
}
.home-swiper {
  margin-bottom: 150px;
}
.services {
  margin-bottom: 100px;
}
.clients {
  margin-bottom: 140px;
}
@media (max-width: 762px) {
  .home-swiper {
    margin-bottom: 100px;
  }
  .banners {
    margin-bottom: 50px;
  }
  .services {
    margin-bottom: 50px;
  }
  .clients {
    margin-bottom: 100px;
  }
}
</style>
