<template>
  <div>
    <div class="services-row__container" :id="`services${idx}`">
      <div
        class="services-row__title-wrapper"
        @click="mark(this.idx, this.$refs.rowBody.scrollHeight)"
      >
        <div class="services-row__title-container">
          <p class="services-row__title">{{ title }}</p>
          <p class="services-row__subtitle">{{ subtitle }}</p>
        </div>
        <div class="services-row__title-arrow">
          <img
            class="services-row__title-arrow_img"
            :class="{ 'services-row__title-arrow_img-active': isOpen }"
            :src="arrowImg"
            alt=""
          />
        </div>
      </div>

      <div
        class="services-row__body"
        ref="rowBody"
        :class="{ isToggleRow: !isOpen }"
      >
        <div class="services-row__body-text">
          <p class="indent" v-html="description"></p>
          <p v-html="descriptionTwo"></p>
        </div>

        <div class="services-row__body-btn-wrapper">
          <div class="services-row__body-btn" @click="isOpenForm = true">
            оставить заявку
          </div>
        </div>
      </div>
    </div>
    <app-sub-app-vue
      :id="idx"
      @closeForm="isOpenForm = false"
    ></app-sub-app-vue>
  </div>
</template>

<script>
import AppSubAppVue from "../ModalWindows/AppSubApp.vue";
import { computed } from "vue";

export default {
  data() {
    return {
      arrowImg: require("@/assets/img/services/arrow.svg"),

      isOpenForm: false,
    };
  },
  props: [
    "title",
    "subtitle",
    "description",
    "descriptionTwo",
    "idx",
    "isOpen",
    "isHeightValue",
  ],
  provide() {
    return {
      isOpenForm: computed(() => this.isOpenForm),
    };
  },
  methods: {
    mark(idx, scrollHeight) {
      this.$emit("mark", idx, scrollHeight);
    },
  },
  mounted() {
    this.$refs.rowBody.style.maxHeight =
      this.$refs.rowBody.scrollHeight + 50 + "px";
  },
  updated() {
    this.$nextTick(function () {
      this.$refs.rowBody.style.maxHeight =
        this.$refs.rowBody.scrollHeight + 50 + "px";
    });
  },
  components: { AppSubAppVue },
};
</script>

<style lang="scss" scoped>
$maxWidth: 1920;
@mixin adaptiv-font($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $maxWidth: $maxWidth - 375;
  font-size: calc(
    #{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth})
  );
  @media (min-width: 1920px) {
    font-size: $pcSize + px;
  }
}

@mixin adaptiv-line-height($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $maxWidth: $maxWidth - 375;
  line-height: calc(
    #{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth})
  );
  @media (min-width: 1920px) {
    line-height: $pcSize + px;
  }
}

.services-row__container {
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  padding-bottom: 50px;

  border-bottom: solid 2px #dcdcdc;
}
.services-row__title-wrapper {
  display: flex;
  justify-content: space-between;
}
.services-row__title-container {
  display: flex;
  flex-direction: column;

  row-gap: 30px;
}
.services-row__title {
  color: #282728;
  font-family: Inter, sans-serif;
  @include adaptiv-font(43, 19);
  font-style: normal;
  font-weight: 600;
  @include adaptiv-line-height(38, 17);
}
.services-row__subtitle {
  color: #dcdcdc;
  font-family: Inter, sans-serif;
  @include adaptiv-font(25, 12);
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  @include adaptiv-line-height(38, 17);
  text-transform: capitalize;
}
.services-row__title-arrow {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 84px;
  height: 60px;

  border-radius: 30px;
  border: 1px solid #29344d;

  cursor: pointer;
}
.services-row__title-arrow_img {
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
}
.services-row__title-arrow_img-active {
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
  transform: rotate(90deg);
}
.services-row__body {
  display: flex;
  flex-direction: column;

  overflow: hidden;

  transition: all 0.3s linear 0s;
}
.isToggleRow {
  transition: all 0.3s linear 0s;

  max-height: 0 !important;
}
.services-row__body-text {
  display: flex;
  justify-content: space-between;

  margin-bottom: 40px;

  max-width: 1400px;
  width: 100%;
  p {
    max-width: 600px;
    width: 100%;

    color: #282728;
    font-family: Inter, sans-serif;
    @include adaptiv-font(18, 12);
    font-style: normal;
    font-weight: 400;
    @include adaptiv-line-height(40, 18);
  }
}
.indent {
  margin-right: 30px;
}
.services-row__body-btn {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 162px;
  height: 44px;

  overflow: hidden;

  color: #fff;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 333.333% */
  text-transform: uppercase;

  background-color: #282728;

  cursor: pointer;

  &::after {
    content: "";
    display: block;
    width: 20px;
    height: 200px;
    margin-left: 50px;
    background: #fff;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0.5) 100%
    );
    left: -40px;
    top: -100px;
    z-index: 1;
    transform: rotate(45deg);
    position: absolute;
    animation: movingFlare 3s ease-in-out 0.05s infinite;
  }

  @keyframes movingFlare {
    0% {
      left: -30px;
      margin-left: 0px;
    }
    30% {
      left: 110%;
      margin-left: 80px;
    }
    100% {
      left: 110%;
      margin-left: 80px;
    }
  }
}
.services-row__body-btn-wrapper {
  height: 44px;
}

@media (max-width: 762px) {
  .services-row__body-text {
    flex-direction: column;
  }
  .indent {
    margin: 0;
    margin-top: 15px;
    margin-bottom: 30px;
  }
  .services-row__title-arrow {
    width: 50px;
    height: 35px;
  }
  .services-row__title-arrow_img {
    width: 15px;
    height: 15px;
  }
  .services-row__container {
    padding-bottom: 55px;
    row-gap: 0;
  }
}
</style>
