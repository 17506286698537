<template>
  <router-link :to="'/product/' + id">
    <div class="card__container">
      <div class="card__figure">
        <img class="card__figure-img" :src="img" alt="" />
        <div
          class="card__figure-icon"
          @mouseover="hov"
          @mouseout="unHov"
          @click.prevent="isOpen"
        >
          <img
            class="card__figure-arrow"
            :class="{ 'card__figure-arrow-active': isHoverIcon }"
            :src="arrowImg"
            alt=""
          />
          <img class="card__figure-box" :src="boxImg" alt="" />
        </div>
      </div>

      <div class="card__body">
        <p class="card__subtitle">{{ subtitle }}</p>
        <p class="card__title">{{ title }}</p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  emits: ["isHoverIconTrue", "isHoverIconFalse", "isOpen"],
  props: ["img", "id", "isHoverIcon", "isOpenImg", "subtitle", "title"],
  data() {
    return {
      arrowImg: require("@/assets/img/CardProduct/arrow.svg"),
      boxImg: require("@/assets/img/CardProduct/box.svg"),
    };
  },
  methods: {
    hov() {
      this.$emit("isHoverIconTrue", this.id);
    },
    unHov() {
      this.$emit("isHoverIconFalse", this.id);
    },
    isOpen() {
      this.$emit("isOpen", this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.card__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  max-width: 430px;
  height: 460px;

  border-radius: 24px;
  background: #fff;

  cursor: pointer;
}
.card__figure {
  position: relative;

  width: 100%;
  height: 337px;

  padding-top: 20px;
}
.card__figure-img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.card__figure-icon {
  position: absolute;
  top: 24px;
  right: 38px;
}
.card__figure-arrow {
  position: absolute;
  top: -2px;
  left: 0;

  width: 18px;
  height: 18px;
}
.card__figure-arrow-active {
  animation: move__arrow 1s linear infinite;
}

@keyframes move__arrow {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(2px, -2px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
.card__figure-box {
  position: absolute;
  top: 0;
  left: 0;

  width: 18px;
  height: 18px;
}
.card__body {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  margin: 0 24px;
}
.card__subtitle {
  color: #9a9aa6;
  font-family: Roboto Mono, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
}
.card__title {
  overflow: hidden;

  color: #28282d;
  text-overflow: ellipsis;
  font-family: Roboto Mono, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 124%; /* 24.8px */
  letter-spacing: -0.6px;
  word-wrap: break-word;
}
</style>
