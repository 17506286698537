<template>
  <div class="home-form__wrapper">
    <div class="home-form__container container">
      <div class="home-form__header">
        <p class="home-form__title">
          У вас есть <br />
          вопросы?
        </p>
        <p class="home-form__subtitle">
          Наш менеджер обработает <br />
          вашу заявку и свяжется с вами <br />
          в ближайшее время
        </p>
      </div>
      <form class="home-form__body" @submit.prevent="submitForm()">
        <div class="home-form__body-wrapper">
          <div class="home-form__title">
            <input
              v-model.trim="name"
              type="text"
              name=""
              id=""
              placeholder="Имя *"
              required
            />
            <input
              v-model.trim="company"
              type="text"
              name=""
              id=""
              placeholder="Компания"
            />
          </div>
          <input
            v-model="tel"
            @click="tel == '' ? (tel = '+998') : true"
            type="tel"
            name=""
            id=""
            placeholder="Номер телефона *"
            required
          />
          <input
            v-model="email"
            type="email"
            name=""
            id=""
            placeholder="Email"
          />
        </div>
        <div class="home-form__btn-container">
          <button
            class="home-form__btn"
            :class="{ 'home-form__btn-active': sent }"
            type="submit"
            @mouseover="mouseover = true"
            @mouseout="mouseover = false"
          >
            {{ btnText }}
            <span class="checkmark"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 24 24"
              >
                <polyline
                  class="path"
                  :class="{ 'change-color__checkmark': mouseover }"
                  fill="none"
                  points="4,12 9,17 20,6"
                  stroke-width="3"
                /></svg
            ></span>

            <div
              class="main-circle"
              :class="{
                'main-circle-active': startPost,
                'change-color__circle': mouseover,
              }"
            >
              <div
                class="green-circle"
                :class="{ 'change-color__circle': mouseover }"
              >
                <div
                  class="brown-circle"
                  :class="{ 'change-color__circle': mouseover }"
                ></div>
              </div>
            </div>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      name: "",
      tel: "",
      company: "",
      email: "",

      mouseover: false,
      startPost: false,
      sent: false,
    };
  },
  watch: {
    sent() {
      const checkmark = document.querySelector(".checkmark");
      if (this.sent) {
        setTimeout(function () {
          checkmark.classList.add("show-checkmark");
        }, 600);
      }
    },
  },
  methods: {
    async submitForm() {
      this.startPost = true;

      if (this.email == "") {
        this.email = null;
      }
      if (this.company == "") {
        this.company = null;
      }

      await axios.post(
        "https://bot.shtar.uz/backend/api/v1/leaveARequest/questions/",
        {
          name: this.name,
          phoneNumber: this.tel,
          companyName: this.company,
          email: this.email,
        }
      );

      this.startPost = false;
      this.sent = true;

      this.name = "";
      this.tel = "";
      this.company = "";
      this.email = "";
    },
  },
  computed: {
    btnText() {
      if (this.sent) {
        return "";
      } else if (this.startPost) {
        return "";
      } else if (!this.sent) {
        return "оставить заявку";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$maxWidth: 1920;
@mixin adaptiv-font($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $maxWidth: $maxWidth - 375;
  font-size: calc(
    #{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth})
  );
	@media (min-width: 1920px) {
		font-size: $pcSize + px;
	}
}

@mixin adaptiv-line-height($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $maxWidth: $maxWidth - 375;
  line-height: calc(
    #{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth})
  );
    	@media (min-width: 1920px) {
		line-height: $pcSize + px;
	}
}

.home-form__wrapper {
  width: 100%;

  padding: 115px 0;

  background: #282728;
}
.container {
  max-width: 1520px;

  margin: 0 auto;
}
.home-form__container {
  display: flex;
  justify-content: space-between;
}
.home-form__header {
  display: flex;
  flex-direction: column;
  row-gap: 84px;
}
.home-form__title {
  display: flex;
  column-gap: 11px;

  width: 100%;

  color: #fff;
  font-family: Inter, sans-serif;
  @include adaptiv-font(94, 43);
  font-style: normal;
  font-weight: 600;
  line-height: 101%;
  text-transform: uppercase;

  input {
    width: 50%;
  }
}
.home-form__subtitle {
  color: #fff;
  font-family: Inter, sans-serif;
  @include adaptiv-font(23, 12);
  font-style: normal;
  font-weight: 400;
  @include adaptiv-line-height(29, 14);
}
.home-form__body {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  max-width: 535px;
  width: 100%;
  input {
    padding: 30px 23px;

    color: #fff;
    font-family: Inter, sans-serif;
    font-size: 18.556px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.556px; /* 100% */

    outline: none;
    border-radius: 24px;
    border: 1px solid #fff;

    background: #282728;

    &,
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    &::placeholder {
      color: #656467;
      font-family: Inter, sans-serif;
      @include adaptiv-font(18, 13);
      font-style: normal;
      font-weight: 400;
      line-height: 18.556px; /* 100% */
    }
  }
  .home-form__body-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 11px;

    width: 100%;
  }
  .home-form__btn-container {
    display: flex;
    justify-content: flex-end;

    width: 100%;
    .home-form__btn {
      display: flex;
      align-items: center;
      justify-content: center;

      max-width: 278px;
      width: 100%;
      height: 79px;

      color: #fff;
      font-family: Inter, sans-serif;
      @include adaptiv-font(22, 14);
      font-style: normal;
      font-weight: 400;
      line-height: 22.347px; /* 100% */

      border-radius: 40px;
      border: 2px solid #fff;

      background: #282728;

      cursor: pointer;
      transition: all 0.2s ease-in-out 0s;

      &:hover {
        transition: all 0.2s ease-in-out 0s;

        background-color: #fff;
        color: #282728;
      }
    }
    .modal-form__btn-active {
      transition: width 0.6s ease-in-out 0s,
        background-color 0.6s ease-in-out 0s,
        border-radius 0.1s ease-in-out 0.5s;
      width: 60px;
      border-radius: 50%;
      background-color: green;
    }
  }
}

.checkmark {
  display: none;
}

.show-checkmark {
  display: flex;
}

.checkmark {
  height: 52px;
  svg {
    width: 24px;
    height: auto;
    padding: 0;
    padding-left: 1px;
  }
}

.checkmark {
  justify-content: center;
  align-items: center;

  svg {
    .path {
      stroke: #fff;
      stroke-linecap: round;
      stroke-dasharray: 30;
      stroke-dashoffset: 30;
      animation: dash 0.5s ease forwards;
      -webkit-animation: dash 0.5s ease forwards;
    }
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.main-circle {
  display: none;

  width: 40px;
  height: 40px;
  border: 2px solid #fff;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  position: relative;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  animation: rotate 2s infinite;
}
.main-circle-active {
  display: flex;
}
.main-circle:before {
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  border: 2px solid transparent;
  border-right: 2px solid #fff;
  transform: rotate(40deg);
  border-radius: 50%;
}
.green-circle {
  animation: rotate 2s infinite 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  transform: rotate(-20deg);
  border-radius: 50%;
  position: relative;
}
.green-circle:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  border: 2px solid transparent;
  border-right: 2px solid #fff;
  transform: rotate(60deg);
}
.brown-circle {
  animation: rotate 2s infinite 0.6s;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  transform: rotate(-20deg);
  border-radius: 50%;
}
@keyframes rotate {
  from {
  }
  to {
    transform: rotate(360deg);
  }
}
.change-color__circle {
  border-color: #282728;
}
.change-color__checkmark {
  stroke: #282728 !important;
}

@media (max-width: 1560px) {
  .home-form__container {
    padding: 0 20px;
  }
}

@media (max-width: 1000px) {
  .home-form__container {
    flex-direction: column;
    align-items: center;
    row-gap: 32px;
  }
  .home-form__header {
    row-gap: 25px;
  }
  .home-form__subtitle {
    text-align: center;
  }
  .home-form__body {
    .home-form__btn-container {
      justify-content: center;
    }
  }
}
@media (max-width: 762px) {
  .home-form__wrapper {
    padding: 50px 0 70px 0;
  }
  .home-form__body {
    .home-form__body-wrapper {
      row-gap: 30px;
    }
  }
  .home-form__title,
  .home-form__body {
    input {
      height: 50px;
      box-sizing: border-box;
    }
  }
}
@media (max-width: 500px) {
  .home-form__body {
    row-gap: 30px;
    .home-form__btn-container {
      button {
        width: 250px;
        height: 60px;
      }
    }
  }
}
</style>
