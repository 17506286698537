<template>
  <div class="header__link"><slot /></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.header__link {
  position: relative;

  color: #282728;
  font-family: Inter, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 15.68px;

  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    top: 40px;
    left: 50%;

    width: 6px;
    height: 6px;

    background-color: #282728;
    border-radius: 50%;
    opacity: 0;

    transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
  }
  &:hover {
    &::before {
      transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;

      transform: translateY(-15px);
      opacity: 1;
    }
  }
}
</style>
