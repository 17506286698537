<template>
  <div
    class="card__open-img__container"
    :class="{
      'card__open-img__container-active':
        this.cards[this.idxCardOpenImg].isOpenImg,
    }"
  >
    <div class="card__open-img-bgr" @click="isOpen"></div>
    <div class="card__open-img__img-container">
      <img :src="this.cards[this.idxCardOpenImg].cardimg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["cards", "idxCardOpenImg"],
  methods: {
    isOpen() {
      this.$emit("isOpen", this.cards[this.idxCardOpenImg].id);
    },
  },
};
</script>

<style lang="scss" scoped>
.card__open-img__container {
  display: none;

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  overflow: hidden;
  opacity: 0;

  z-index: 20;
}
.card__open-img__container-active {
  display: block;

  overflow: visible;
  opacity: 1;
}
.card__open-img-bgr {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: #00000046;
}
.card__open-img__img-container {
  position: absolute;
  top: 50%;
  left: 50%;

  max-width: 805px;
  max-height: 500px;

  overflow: hidden;

  transform: translate(-50%, -50%);

  padding: 30px 10px;

  background-color: #fff;
  border-radius: 24px;

  img {
    width: 100%;
    height: 100%;

    object-fit: contain;
  }
}
@media (max-width: 762px) {
  .card__open-img__img-container {
    max-width: 95vw;
    box-sizing: border-box;

    img {
      width: 85vw;
      object-fit: contain;
    }
  }
}
</style>
