<template>
  <header class="header-wrapper" :class="{ 'header-scrolled': isScrolled }">
    <div class="container">
      <div class="header__container">
        <router-link to="/home">
          <div class="header__logo">
            <img :src="logoImg" alt="" />
          </div>
        </router-link>
        <nav class="header__nav">
          <router-link to="/home">
            <app-header-link>Главная</app-header-link>
          </router-link>
          <router-link to="/home#about">
            <app-header-link>О компании</app-header-link></router-link
          >
          <router-link to="/home#services">
            <app-header-link>Мы предоставляем</app-header-link></router-link
          >
          <router-link to="/home#clients">
            <app-header-link>Нам доверяют</app-header-link></router-link
          >
        </nav>
        <div class="header__btn-container">
          <div class="header__catalog-btn" @click="$emit('clickBtnCatalog')">
            <p>Каталог</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="13"
              viewBox="0 0 7 13"
              fill="none"
              class="header__catalog-btn-arrow"
            >
              <path
                d="M0.734375 11.5L5.73438 6.5L0.734375 1.5"
                stroke-width="1.38095"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="path"
              />
            </svg>
          </div>
          <router-link to="#contacts">
            <div class="header__contacts-btn">Контакты</div>
          </router-link>
          <a
            :href="downloadFile"
            download
            target="_blank"
            class="header__pdf-btn"
          >
            <img :src="pdfImg" alt="" />
            <p>Скачать прайс</p>
          </a>
          <div
            class="header__menu-btn__mob"
            @click="toggleMenuMob"
            :class="{ 'header__menu-btn__mob-active': headerMenuBtn }"
          >
            <div
              class="header__menu-btn-circle"
              :class="{ 'header__menu-btn-cross': headerMenuBtn }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="header__menu-mob-container"
      :class="{ 'display-none': !headerMenuBtn }"
    >
      <div class="header__menu-mob">
        <nav class="header__nav-mob">
          <router-link to="/home" @click="toggleMenuMob">
            <app-header-link>Главная</app-header-link>
          </router-link>
          <router-link to="/home#about" @click="toggleMenuMob">
            <app-header-link>О компании</app-header-link></router-link
          >
          <router-link to="/home#services" @click="toggleMenuMob">
            <app-header-link>Мы предоставляем</app-header-link></router-link
          >
          <router-link to="/home#clients" @click="toggleMenuMob">
            <app-header-link>Нам доверяют</app-header-link></router-link
          >
        </nav>
        <a href="" download="" class="header__pdf-btn-mob">
          <img :src="pdfImg" alt="" />
          <p>Скачать прайс</p>
        </a>
        <a class="header__tel-mob" :href="linkFirstNumberPhone">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
          >
            <g clip-path="url(#clip0_55_918)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.99354 1.20706C2.14664 1.05419 2.33048 0.935601 2.53288 0.859154C2.73529 0.782706 2.95162 0.750147 3.16756 0.763632C3.3835 0.777118 3.5941 0.836341 3.78542 0.937377C3.97673 1.03841 4.14439 1.17895 4.27729 1.34969L5.84791 3.36744C6.13579 3.73756 6.23729 4.21969 6.12354 4.67469L5.64491 6.59094C5.62017 6.69019 5.6215 6.79415 5.64879 6.89273C5.67608 6.99131 5.7284 7.08117 5.80066 7.15356L7.95054 9.30344C8.02302 9.37585 8.11302 9.42825 8.21177 9.45554C8.31052 9.48284 8.41466 9.48409 8.51404 9.45919L10.4294 8.98056C10.6539 8.92442 10.8883 8.92006 11.1148 8.96781C11.3413 9.01556 11.5539 9.11417 11.7367 9.25619L13.7544 10.8259C14.4798 11.3903 14.5463 12.4622 13.897 13.1106L12.9923 14.0153C12.3448 14.6628 11.377 14.9472 10.4749 14.6296C8.16595 13.8171 6.06953 12.4953 4.34116 10.7621C2.60804 9.03395 1.28619 6.93784 0.473662 4.62919C0.156912 3.72794 0.441287 2.75931 1.08879 2.11181L1.99354 1.20706ZM14.2164 0.887687C14.2572 0.928327 14.2895 0.976606 14.3115 1.02976C14.3336 1.08291 14.3449 1.13989 14.3449 1.19744C14.3449 1.25498 14.3336 1.31196 14.3115 1.36512C14.2895 1.41827 14.2572 1.46655 14.2164 1.50719L10.5878 5.13494H13.0317C13.1477 5.13494 13.259 5.18103 13.341 5.26308C13.4231 5.34513 13.4692 5.45641 13.4692 5.57244C13.4692 5.68847 13.4231 5.79975 13.341 5.8818C13.259 5.96384 13.1477 6.00994 13.0317 6.00994H9.53166C9.41563 6.00994 9.30435 5.96384 9.2223 5.8818C9.14026 5.79975 9.09416 5.68847 9.09416 5.57244V2.07244C9.09416 1.95641 9.14026 1.84513 9.2223 1.76308C9.30435 1.68103 9.41563 1.63494 9.53166 1.63494C9.64769 1.63494 9.75898 1.68103 9.84102 1.76308C9.92307 1.84513 9.96916 1.95641 9.96916 2.07244V4.51631L13.5969 0.887687C13.6376 0.846944 13.6858 0.81462 13.739 0.792564C13.7921 0.770508 13.8491 0.759155 13.9067 0.759155C13.9642 0.759155 14.0212 0.770508 14.0743 0.792564C14.1275 0.81462 14.1758 0.846944 14.2164 0.887687Z"
                fill="#29344D"
              />
            </g>
            <defs>
              <clipPath id="clip0_55_918">
                <rect
                  width="14"
                  height="14"
                  fill="white"
                  transform="translate(0.344238 0.76001)"
                />
              </clipPath>
            </defs>
          </svg>
          {{ prettify(firstNumberPhone) }}
        </a>
        <a class="header__tel-mob" :href="linkSecondNumberPhone">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
          >
            <g clip-path="url(#clip0_55_918)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.99354 1.20706C2.14664 1.05419 2.33048 0.935601 2.53288 0.859154C2.73529 0.782706 2.95162 0.750147 3.16756 0.763632C3.3835 0.777118 3.5941 0.836341 3.78542 0.937377C3.97673 1.03841 4.14439 1.17895 4.27729 1.34969L5.84791 3.36744C6.13579 3.73756 6.23729 4.21969 6.12354 4.67469L5.64491 6.59094C5.62017 6.69019 5.6215 6.79415 5.64879 6.89273C5.67608 6.99131 5.7284 7.08117 5.80066 7.15356L7.95054 9.30344C8.02302 9.37585 8.11302 9.42825 8.21177 9.45554C8.31052 9.48284 8.41466 9.48409 8.51404 9.45919L10.4294 8.98056C10.6539 8.92442 10.8883 8.92006 11.1148 8.96781C11.3413 9.01556 11.5539 9.11417 11.7367 9.25619L13.7544 10.8259C14.4798 11.3903 14.5463 12.4622 13.897 13.1106L12.9923 14.0153C12.3448 14.6628 11.377 14.9472 10.4749 14.6296C8.16595 13.8171 6.06953 12.4953 4.34116 10.7621C2.60804 9.03395 1.28619 6.93784 0.473662 4.62919C0.156912 3.72794 0.441287 2.75931 1.08879 2.11181L1.99354 1.20706ZM14.2164 0.887687C14.2572 0.928327 14.2895 0.976606 14.3115 1.02976C14.3336 1.08291 14.3449 1.13989 14.3449 1.19744C14.3449 1.25498 14.3336 1.31196 14.3115 1.36512C14.2895 1.41827 14.2572 1.46655 14.2164 1.50719L10.5878 5.13494H13.0317C13.1477 5.13494 13.259 5.18103 13.341 5.26308C13.4231 5.34513 13.4692 5.45641 13.4692 5.57244C13.4692 5.68847 13.4231 5.79975 13.341 5.8818C13.259 5.96384 13.1477 6.00994 13.0317 6.00994H9.53166C9.41563 6.00994 9.30435 5.96384 9.2223 5.8818C9.14026 5.79975 9.09416 5.68847 9.09416 5.57244V2.07244C9.09416 1.95641 9.14026 1.84513 9.2223 1.76308C9.30435 1.68103 9.41563 1.63494 9.53166 1.63494C9.64769 1.63494 9.75898 1.68103 9.84102 1.76308C9.92307 1.84513 9.96916 1.95641 9.96916 2.07244V4.51631L13.5969 0.887687C13.6376 0.846944 13.6858 0.81462 13.739 0.792564C13.7921 0.770508 13.8491 0.759155 13.9067 0.759155C13.9642 0.759155 14.0212 0.770508 14.0743 0.792564C14.1275 0.81462 14.1758 0.846944 14.2164 0.887687Z"
                fill="#29344D"
              />
            </g>
            <defs>
              <clipPath id="clip0_55_918">
                <rect
                  width="14"
                  height="14"
                  fill="white"
                  transform="translate(0.344238 0.76001)"
                />
              </clipPath>
            </defs>
          </svg>
          {{ prettify(secondNumberPhone) }}
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import AppHeaderLink from "./AppHeaderLink.vue";

import { prettify } from "@/use/prettify.js";
import axios from "axios";
export default {
  emits: ["clickBtnCatalog"],
  data() {
    return {
      logoImg: require("@/assets/img/header/logo_header.svg"),
      pdfImg: require("@/assets/img/header/pdf_header.svg"),
      isScrolled: false,
      headerMenuBtn: false,
      prettify,

      footerContent: {},
      firstNumberPhone: "",
      secondNumberPhone: "",
      downloadFile: "",
    };
  },
  components: { AppHeaderLink },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    async function getFooterContent() {
      const response = await axios.get(
        `https://bot.shtar.uz/backend/api/v1/site/content/2/`
      );

      return response.data;
    }
    getFooterContent().then((result) => {
      this.footerContent = result;
    });

    async function getDownloadFile() {
      const response = await axios.get(
        `https://bot.shtar.uz/backend/download/1/`
      );

      return response.data;
    }
    getDownloadFile().then((result) => {
      this.downloadFile = result.pdf_file;
    });
  },

  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    },
    toggleMenuMob() {
      this.headerMenuBtn = !this.headerMenuBtn;
      if (this.headerMenuBtn) {
        document.body.style = "overflow: hidden;";
      } else {
        document.body.style = "";
      }
    },
  },
  watch: {
    footerContent() {
      this.firstNumberPhone = this.footerContent.contact.phoneNumber;
      this.secondNumberPhone = this.footerContent.contact.phoneNumberTwo;
    },
  },
  computed: {
    linkFirstNumberPhone() {
      return "tel:+998" + this.firstNumberPhone;
    },
    linkSecondNumberPhone() {
      return "tel:+998" + this.secondNumberPhone;
    },
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.header__menu-mob-container {
  display: none;
}
.header-wrapper {
  width: 100%;

  padding: 70px 0;

  border-radius: 0 0 24px 24px;

  transition: transform 0.2s linear 0s, padding 0.2s linear 0s,
    background 0.2s linear 0s, backdrop-filter 0s linear 0s;
}
.header-scrolled {
  transition: transform 0.2s linear 0s, padding 0.2s linear 0s,
    background 0.2s linear 0s, backdrop-filter 0s linear 0.3s;

  transform: translateY(-50px);

  padding: 70px 0 20px 0;

  backdrop-filter: blur(5px);
  background: hsla(0, 0%, 100%, 0.7);
}
.container {
  max-width: 1520px;

  margin: 0 auto;
}
header {
  position: sticky;
  top: 0;
  left: 0;

  z-index: 25;
}
.header__container {
  display: flex;
  justify-content: space-between;

  max-width: 1200px;
  width: 100%;
}

.header__nav {
  display: flex;
  align-items: center;
  column-gap: 48px;
}

.header__btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 450px;
  width: 100%;

  .header__catalog-btn {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 11px;

    width: 122px;
    height: 49px;

    overflow: hidden;

    border-radius: 90px;
    border: 1px solid #282728;

    color: #282728;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;

    cursor: pointer;
    transition: all 0.2s ease-in-out 0s;
    &:hover {
      transition: all 0.2s ease-in-out 0s;

      color: #fff;
      background-color: #282728;
      .path {
        transition: all 0.2s ease-in-out 0s;
        stroke: #fff;
      }
    }
  }
  .path {
    transition: all 0.2s ease-in-out 0s;
    stroke: #282728;
  }
  .header__contacts-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 102px;
    height: 49px;

    background: #282728;

    border-radius: 30px;

    color: #fff;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;

    cursor: pointer;
    transition: all 0.2s ease-in-out 0s;

    &:hover {
      transition: all 0.2s ease-in-out 0s;
      background-color: #000;
    }
  }
  .header__pdf-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 3px;

    width: 150px;
    height: 49px;

    background: #29344d;

    border-radius: 90px;

    color: #fff;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;

    cursor: pointer;
    transition: all 0.2s ease-in-out 0s;

    &:hover {
      transition: all 0.2s ease-in-out 0s;
      background-color: #091633;
    }
  }
}
.header__menu-btn__mob {
  display: none;
  align-items: center;
  justify-content: center;

  width: 45px;
  height: 45px;

  background-color: #282728;
  border-radius: 50%;

  transition: all 0.2s linear 0s;

  z-index: 27;
}
.header__menu-btn-circle {
  position: relative;

  width: 5px;
  height: 5px;

  border-radius: 50%;
  background-color: #fff;
  transition: all 0.2s linear 0s;

  &::before,
  &::after {
    position: absolute;
    content: "";

    width: 5px;
    height: 5px;

    border-radius: 50%;
    background-color: #fff;
    transition: all 0.2s linear 0s;
  }
  &::before {
    top: 0;
    left: -10px;
  }
  &::after {
    top: 0;
    left: 10px;
  }
}

.header__menu-btn-cross {
  width: 0;
  height: 0;

  border-radius: 0;
  background-color: transparent;

  transition: all 0.2s linear 0s;

  &::before,
  &::after {
    position: absolute;
    content: "";

    width: 1px;
    height: 35px;

    border-radius: 0;
    background-color: #282728;

    transition: all 0.2s linear 0s;
  }
  &::before {
    transform: translate(10px, -17px) rotate(45deg);
  }
  &::after {
    transform: translate(-10px, -17px) rotate(-45deg);
  }
}
.header__menu-btn__mob-active {
  background-color: transparent;
  transition: all 0.2s linear 0s;
}

.display-none {
  display: none !important;
}

@media (max-width: 1560px) {
  .container {
    padding: 0 20px;
  }
}
@media (max-width: 1200px) {
  .header__menu-mob-container {
    display: flex;
    position: fixed;
    align-items: center;
    top: 0;
    left: 0;

    width: 100vw;
    height: calc(100vh + 50px);

    background-color: #dfdfdf;

    z-index: 25;
  }
  .header__menu-mob {
    display: flex;
    flex-direction: column;
    row-gap: 35px;

    padding-left: 35px;
  }
  .header__nav-mob {
    display: flex;
    flex-direction: column;
    row-gap: 45px;
  }
  .header__pdf-btn-mob {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 3px;

    width: 150px;
    height: 49px;

    background: #29344d;

    border-radius: 90px;

    color: #fff;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;

    cursor: pointer;
  }
  .header__tel-mob {
    display: flex;
    align-items: center;
    column-gap: 10px;

    color: #282728;
    font-family: Inter, sans-serif;
    font-size: 15.587px;
    font-style: normal;
    font-weight: 500;
    line-height: 20.367px; /* 130.667% */
  }
  .header__menu-btn__mob {
    display: flex;
  }
  .header__nav {
    display: none;
  }
  .header__btn-container {
    max-width: 600px;
  }

  .header__logo {
    margin-right: 30px;
  }
}
@media (max-width: 600px) {
  .header-wrapper {
    padding: 10px 0;
  }
  .header-scrolled {
    transform: translateY(-10px);

    padding: 20px 0 10px 0;
  }
  .header__logo {
    img {
      width: 53px;
      height: 56px;
    }
  }
  .header__btn-container {
    justify-content: flex-end;
    column-gap: 28px;
    .header__contacts-btn,
    .header__pdf-btn {
      display: none;
    }
  }
}
</style>
