<template>
  <div id="about" class="banner__about">
    <p
      class="banner__title"
      :class="{ 'banner__title-active': isActiveTitleAnimation }"
      ref="aboutTitle"
    >
      SHTAR SYSTEM
    </p>
    <div class="banner__body">
      <p v-html="mainPageDescription"></p>
      <p v-html="mainPageDescriptionTwo"></p>
    </div>
    <div class="banner__icon-container">
      <div class="banner__icon">
        <img :src="firtsImg" alt="" />
        <p>Профессиональная техническая поддержка</p>
      </div>
      <div class="banner__icon">
        <img :src="secondImg" alt="" />
        <p>Качественные услуги и продукция</p>
      </div>
      <div class="banner__icon">
        <img :src="thirdImg" alt="" />
        <p>Услуги доступны по всей Республике Узбекистан</p>
      </div>
    </div>
    <div class="swiper__container">
      <p>Производители оборудования:</p>
      <app-banner-swiper-vue></app-banner-swiper-vue>
    </div>
  </div>
</template>

<script>
import AppBannerSwiperVue from "./AppBannerSwiper.vue";
export default {
  inject: ["pageContentt"],
  data() {
    return {
      firtsImg: require("@/assets/img/banners/banner_about/icon_1.png"),
      secondImg: require("@/assets/img/banners/banner_about/icon_2.png"),
      thirdImg: require("@/assets/img/banners/banner_about/icon_3.png"),
      topElement: null,
      topPage: null,
      isActiveTitleAnimation: false,

      mainPageDescription: this.pageContentt.mainPageDescription,
      mainPageDescriptionTwo: this.pageContentt.mainPageDescriptionTwo,
    };
  },

  components: { AppBannerSwiperVue },

  mounted() {
    this.topElement = this.$refs.aboutTitle.getBoundingClientRect().top;

    document.addEventListener("scroll", () => {
      this.topPage = window.pageYOffset + innerHeight;
    });
  },
  watch: {
    topPage() {
      if (this.topPage > this.topElement + 150) {
        this.isActiveTitleAnimation = true;
      } else {
        this.isActiveTitleAnimation = false;
      }
    },
    pageContentt() {
      this.mainPageDescription = this.pageContentt.mainPageDescription;
      this.mainPageDescriptionTwo = this.pageContentt.mainPageDescriptionTwo;
    },
  },
};
</script>

<style lang="scss" scoped>
$maxWidth: 1920;
@mixin adaptiv-font($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $maxWidth: $maxWidth - 375;
  font-size: calc(
    #{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth})
  );

  @media (min-width: 1920px) {
    font-size: $pcSize + px;
  }
}

@mixin adaptiv-line-height($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $maxWidth: $maxWidth - 375;
  line-height: calc(
    #{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth})
  );
  @media (min-width: 1920px) {
    line-height: $pcSize + px;
  }
}

.banner__about {
  display: flex;
  flex-direction: column;
  row-gap: 75px;

  box-sizing: border-box;

  padding: 70px 60px;

  border-radius: 50px;
  background: #29344d;
}

.banner__title {
  --fill-color: #fff;
  position: relative;
  display: block;
  background: linear-gradient(var(--fill-color) 0 100%) left / 0 no-repeat;
  color: #ffffff48;
  background-clip: text;

  font-family: Orbitron, sans-serif;
  @include adaptiv-font(115, 30);
  font-style: normal;
  font-weight: 500;
  @include adaptiv-line-height(105, 25);

  transition: 1s ease-in-out;
}

.banner__title-active {
  background-size: 100%;
  transition: 1s ease-in-out;
}

.banner__body {
  display: flex;
  justify-content: space-between;
  column-gap: 100px;

  width: 100%;
  p {
    color: #fff;
    font-family: Inter, sans-serif;
    @include adaptiv-font(18, 13);
    font-style: normal;
    font-weight: 400;
    line-height: 222%; /* 222.222% */
  }
}
.banner__icon-container {
  display: flex;
  column-gap: 75px;
}
.banner__icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  img {
    width: 130px;
    height: 130px;
  }
  p {
    width: 170px;

    color: #fffeff;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 13.015px;
    font-style: normal;
    font-weight: 600;
    line-height: 16.269px; /* 125% */
  }
}
.swiper__container {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  p {
    color: #fff;
    font-family: Inter, sans-serif;
    @include adaptiv-font(18, 15);
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 111.111% */
  }
}
@media (min-width: 1920px) {
  .banner__title {
    font-size: 140px;
  }
}
@media (max-width: 1560px) {
  .banner__about {
    padding: 40px 30px;
  }
}
@media (max-width: 1170px) {
  .banner__about {
    border-radius: 0;
    row-gap: 65px;

    padding: 70px 20px 40px 20px;
  }
}
@media (max-width: 1000px) {
  .banner__body {
    flex-direction: column;
    row-gap: 35px;
  }
  .banner__icon-container {
    justify-content: space-evenly;
    column-gap: 0;
  }
}
@media (max-width: 762px) {
  .banner__icon-container {
    flex-direction: column;
    row-gap: 20px;
  }
}
</style>
