<template>
  <div class="container">
    <div class="home-swiper__title-container">
      <p class="home-swiper__title">Популярые товары на&nbsp;сайте</p>
      <div class="first-navigation__container">
        <div class="first-navigation-prev">
          <img :src="arrowPagination" alt="" />
        </div>
        <div class="first-navigation-next">
          <img :src="arrowPagination" alt="" />
        </div>
      </div>
    </div>

    <div class="home__swiper" ref="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in cards" :key="item.id">
          <app-card-product-vue
            :id="item.id"
            :img="item.cardimg"
            :isHoverIcon="item.isHoverIcon"
            :isOpenImg="item.isOpenImg"
            :subtitle="item.subtitle"
            :title="item.title"
            @isHoverIconTrue="isHoverIconTrueM"
            @isHoverIconFalse="isHoverIconFalseM"
            @isOpen="isOpenToggle"
          ></app-card-product-vue>
        </div>
      </div>
    </div>

    <app-card-open-img-vue
      :cards="cards"
      :idxCardOpenImg="idxCardOpenImg"
      @isOpen="isOpenToggle"
    ></app-card-open-img-vue>
  </div>
</template>

<script>
import AppCardProductVue from "../CardProduct/AppCardProduct.vue";
import AppCardOpenImgVue from "../CardProduct/AppCardOpenImg.vue";

import Swiper, { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

export default {
  props: ["cardsArray"],
  data() {
    return {
      arrowPagination: require("@/assets/img/CardProduct/arrow_pagination.svg"),
      idxCardOpenImg: 1,
      cards: this.cardsArray,
    };
  },

  components: { AppCardProductVue, AppCardOpenImgVue },
  methods: {
    isHoverIconTrueM(id) {
      const idx = this.cards.findIndex((card) => {
        return card.id === id;
      });
      this.cards[idx].isHoverIcon = true;
    },
    isHoverIconFalseM(id) {
      const idx = this.cards.findIndex((card) => {
        return card.id === id;
      });
      this.cards[idx].isHoverIcon = false;
    },
    isOpenToggle(id) {
      const idx = this.cards.findIndex((card) => {
        return card.id === id;
      });
      this.idxCardOpenImg = idx;
      this.cards[idx].isOpenImg = !this.cards[idx].isOpenImg;
    },
    onWindowResize() {
      if (this.swiper) {
        this.swiper.destroy();
        this.initSwiper();
      }
    },
  },
  mounted() {
    new Swiper(this.$refs.swiper, {
      modules: [Navigation],
      loop: false,
      slidesPerView: 3,
      spaceBetween: 104,
      grabCursor: true,
      navigation: {
        nextEl: ".first-navigation-next",
        prevEl: ".first-navigation-prev",
      },
      breakpoints: {
        1300: {
          slidesPerView: 3,
        },
        1050: {
          slidesPerView: 2,
        },
        100: {
          slidesPerView: 1,
        },
      },
    });
  },
  setup() {
    const onSwiper = (swiper) => {};
    const onSlideChange = () => {};
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation],
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 1520px;
  margin: 0 auto;
}

.home-swiper__title {
  color: #282728;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 124%; /* 24.8px */
  letter-spacing: -0.6px;
}

.home__swiper {
  overflow: hidden;
}
.home-swiper__title-container {
  display: flex;
  justify-content: space-between;

  margin-bottom: 50px;
}
.first-navigation__container {
  display: flex;
  column-gap: 12px;
}
.first-navigation-next,
.first-navigation-prev {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 48px;
  height: 48px;

  border-radius: 50%;
  background: #e5e5e5;

  cursor: pointer;
}
.first-navigation-prev {
  img {
    transform: rotate(180deg);
  }
}
.swiper-button-disabled {
  opacity: 0.3;
}

@media (max-width: 1560px) {
  .container {
    padding: 0 20px;
  }
}
@media (max-width: 1050px) {
  .swiper-slide {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 762px) {
  .first-navigation__container {
    display: none;
  }
}
</style>
